import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortBy, compact } from 'lodash';
import { reverse } from '../utility';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

const nextSortDirection = direction => {
  if (direction === SORT_DESC) return SORT_ASC;
  else return SORT_DESC;
};

class Table extends Component {
  renderColumn(column, item) {
    const render = column.render || column.value;
    return render(item);
  }

  renderExtra(column, item) {
    const render = column.renderExtra || (() => null);
    return render(item);
  }

  render() {
    const { data, columns, onColumnSort, tableProps } = this.props;

    const rowProps = this.props.rowProps || {};
    const headerRowProps = this.props.headerRowProps || {};

    const sortColumn = columns.filter(
      col => col.sort === SORT_ASC || col.sort === SORT_DESC
    )[0];

    const sortedData = sortColumn
      ? sortColumn.sort === SORT_DESC
        ? reverse(sortBy(data, sortColumn.value))
        : sortBy(data, sortColumn.value)
      : data;

    const rows = sortedData.map((o, i) => {
      const base = [
        <tr key={i} {...rowProps}>
          {columns.map((col, j) => {
            const cellProps = col.cellProps || {};
            return (
              <td key={j} {...cellProps}>
                {this.renderColumn(col, o)}
              </td>
            );
          })}
        </tr>,
      ];

      const extra = compact(
        columns.map(col => {
          if (this.renderExtra) {
            return this.renderExtra(col, o);
          } else {
            return null;
          }
        })
      );

      return [...base, ...extra];
    });

    const head = columns.map(col => {
      const headerCellProps = col.headerCellProps || {};

      const props = {
        onClick: () => {
          if (!col.disableSorting) {
            onColumnSort(col.id, nextSortDirection(col.sort));
          }
        },
        style: col.disableSorting ? {} : { cursor: 'pointer' },
        ...headerCellProps,
      };

      return (
        <th key={col.id} {...props}>
          {col.label}
          {col.sort === SORT_ASC ? (
            <i className="fa fa-chevron-up txt-muted mls" />
          ) : col.sort === SORT_DESC ? (
            <i className="fa fa-chevron-down txt-muted mls" />
          ) : null}
        </th>
      );
    });

    return (
      <table {...tableProps}>
        <thead>
          <tr {...headerRowProps}>{head}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

Table.defaultPropTypes = {
  tableProps: {},
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      value: PropTypes.func,
      render: PropTypes.func,
      renderExtra: PropTypes.func,
      cellProps: PropTypes.object,
      headerCellProps: PropTypes.object,
      disableSorting: PropTypes.bool,
    })
  ),
  onColumnSort: PropTypes.func,
  tableProps: PropTypes.object,
  rowProps: PropTypes.object,
  headerRowProps: PropTypes.object,
};

export default Table;
