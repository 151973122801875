import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { localPutJSON } from '../fetch-local';
import Loader from './loader';

interface DataValue {
  value: number;
  formatted: string;
  updatedAt?: string;
}

interface Supplier {
  id: number;
  label: string;
  itemManagerPrice?: DataValue;
  price?: DataValue;
  inventory?: DataValue;
}

interface Option extends Supplier {
  value: string;
}

interface OptionProps {
  children: string;
  option: Option;
  className: string;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
  onFocus: (a: Option, any) => any;
  onSelect: (a: Option, any) => any;
}

const SupplierOption = (props: OptionProps) => {
  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onSelect(props.option, e);
  };

  const handleMouseEnter = e => {
    props.onFocus(props.option, e);
  };

  const handleMouseMove = e => {
    if (props.isFocused) return;
    props.onFocus(props.option, e);
  };

  return (
    <div
      className={props.className + ' supplier-dropdown-item phm pvs'}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      title={props.option.label}
      role="button"
      tabIndex={-1}
    >
      {props.children}

      {props.option.inventory && (
        <span
          className="txt-success txt-right"
          title={props.option.inventory.updatedAt || ''}
        >
          {props.option.inventory.formatted}
        </span>
      )}

      {props.option.price && (
        <span className="txt-right" title={props.option.price.updatedAt || ''}>
          {props.option.price.formatted}
        </span>
      )}

      {!props.option.inventory && (
        <span className="txt-muted2 txt-right"></span>
      )}

      {!props.option.price && props.option.itemManagerPrice && (
        <span className="txt-right txt-muted2 txt-em">
          {props.option.itemManagerPrice.formatted}
        </span>
      )}
    </div>
  );
};

interface ValueProps {
  children: JSX.Element;
  placeholder: string;
  value: Option;
}

const SupplierValue = ({ value, children }: ValueProps) => {
  return (
    <div
      className="Select-value supplier-dropdown-item mrs"
      title={value.label}
    >
      <span className="Select-value-label">{children}</span>
      {value.inventory && (
        <span
          className="txt-success txt-right"
          title={value.inventory.updatedAt || ''}
        >
          {value.inventory.formatted}
        </span>
      )}

      {value.price && (
        <span
          className="txt-right txt-normal"
          title={value.price.updatedAt || ''}
        >
          {value.price.formatted}
        </span>
      )}

      {!value.inventory && <span className="txt-muted2 txt-right"></span>}

      {!value.price && value.itemManagerPrice && (
        <span className="txt-right txt-muted2 txt-em">
          {value.itemManagerPrice.formatted}
        </span>
      )}
    </div>
  );
};

interface Props {
  name: string;
  orderId: number;
  initialSelectedSupplierId?: number;
  lineItemSizeId: number;
  lineItemId: number;
  suppliers: Supplier[];
}

const SuppliersField = ({
  name,
  suppliers,
  initialSelectedSupplierId,
  lineItemId,
  lineItemSizeId,
  orderId,
}: Props) => {
  const [value, setValue] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const options: Option[] = suppliers.map(supplier => {
    return { ...supplier, value: supplier.id.toString() };
  });

  const saveValue = useCallback(
    (
      value: string | null,
      persist: boolean = true,
      lineItemSizeLevel: boolean = false
    ) => {
      setValue(value);

      window.dispatchEvent(
        new CustomEvent('lineItemSizeSupplierSet', {
          detail: {
            lineItemSizeLevel,
            lineItemSizeId: lineItemSizeId,
            supplierId: value,
          },
        })
      );

      const priceField: HTMLInputElement | null = document.querySelector(
        `[data-size-id="${lineItemSizeId}"]`
      );

      if (priceField) {
        const supplier = suppliers.find(s => s.id.toString() === value);

        if (supplier) {
          const price = supplier.price || supplier.itemManagerPrice;
          if (price) {
            priceField.value = price.value.toFixed(2).toString();
            if (persist)
              priceField.dispatchEvent(new Event('change', { bubbles: true }));
          }
        }
      }

      if (persist) {
        const payload = {
          order: {
            line_items_attributes: {
              0: {
                id: lineItemId,
                line_item_sizes_attributes: {
                  0: { id: lineItemSizeId, supplier_id: value },
                },
              },
            },
          },
        };

        setLoading(true);
        localPutJSON(
          `/orders/purchase_apparel/${orderId}`,
          payload
        ).finally(() => setLoading(false));
      }
    },
    [lineItemId, lineItemSizeId, orderId, suppliers]
  );

  useEffect(() => {
    if (initialSelectedSupplierId === undefined) return;
    setValue(
      initialSelectedSupplierId ? initialSelectedSupplierId.toString() : null
    );
  }, [initialSelectedSupplierId]);

  useEffect(() => {
    const handleEvent = e => {
      if (e.detail.orderId.toString() !== orderId.toString()) return;
      saveValue(
        e.detail.supplierId ? e.detail.supplierId.toString() : null,
        false
      );
    };

    window.addEventListener('orderSupplierSet', handleEvent);

    return () => {
      window.removeEventListener('orderSupplierSet', handleEvent);
    };
  }, [lineItemId, orderId, saveValue]);

  useEffect(() => {
    const handleLineItemSupplierSet = e => {
      if (!e.detail.lineItemId) return;
      if (e.detail.lineItemId.toString() !== lineItemId.toString()) return;
      saveValue(
        e.detail.supplierId ? e.detail.supplierId.toString() : null,
        false
      );
    };

    window.addEventListener('lineItemSupplierSet', handleLineItemSupplierSet);

    return () => {
      window.removeEventListener(
        'lineItemSupplierSet',
        handleLineItemSupplierSet
      );
    };
  }, [lineItemId, saveValue]);

  return (
    <div className="section txt-left">
      <div className="media" style={{ gap: '1rem' }}>
        <div className="media--obj" style={{ width: '100%' }}>
          <Select
            onChange={e => {
              const value = e ? e.value : e;
              saveValue(value, true, true);
            }}
            optionComponent={SupplierOption}
            options={options}
            placeholder={'Select Supplier'}
            name={name}
            value={value}
            valueComponent={SupplierValue}
            matchPos="start"
          />
        </div>
        <div className="media--obj">{loading && <Loader />}</div>
      </div>
    </div>
  );
};

export default SuppliersField;
