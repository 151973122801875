import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Address, AddressIssue, AddressStatus } from '../address-types';
import verify from '../address-verify';
import states from '../us-states';
import { uniqueId, merge } from 'lodash';
import classNames from 'classnames';
import Checkbox from './checkbox';
import { waitForElement } from '../utility';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import { wrapBoldIfDiff } from '../reactUtilities';
import AddressView from './AddressView';
import { simulateReactMouseClick } from '../reactUtilities';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

const prefixId = (v: string, id: string): string => (!!v ? `#${id} ${v}` : v);

type SpacingType = 'x-small' | 'small' | 'large';

interface Field {
  name: string;
  value: string;
  label?: string | null;
}

interface BooleanField {
  name: string;
  value: boolean;
  label?: string | null;
}

interface Fields {
  addressLine1: Field;
  addressLine2: Field;
  city: Field;
  state: Field;
  zip: Field;
  country?: Field;
  internationalAddress?: BooleanField;
}

interface Props {
  addressLine1?: Field;
  addressLine2?: Field;
  city?: Field;
  state?: Field;
  zip?: Field;
  country?: Field;
  internationalAddress?: BooleanField;

  scope?: string;
  disabled?: boolean;
  readOnly?: boolean;
  disableVerification?: boolean;
  spacing?: SpacingType;

  children?: JSX.Element;

  allowInternational?: boolean;
  allowInternationalToggle?: boolean;

  onChange?: (d: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    verified: boolean;
  }) => any;

  preventSubmitUnlessValidated?: boolean;

  renderSubmit?: (handler: any) => JSX.Element;
  onSubmitForm?: () => any;
}

const buildDefaultFields = (international: boolean): Fields => {
  return {
    addressLine1: { name: 'address_1', label: 'Address', value: '' },
    addressLine2: { name: 'address_2', value: '' },
    city: {
      name: 'city',
      label: international ? 'Locality' : 'City',
      value: '',
    },
    state: {
      name: 'state',
      label: international ? 'Region' : 'State',
      value: '',
    },
    zip: {
      name: 'zip',
      label: international ? 'Postal Code' : 'Zip',
      value: '',
    },
    country: {
      name: 'country',
      label: 'Country',
      value: '',
    },
    internationalAddress: {
      name: 'international_address',
      label: 'International Address',
      value: false,
    },
  };
};

export default (props: Props) => {
  const {
    scope,
    spacing,
    renderSubmit,
    onSubmitForm,
    ...providedFields
  } = props;
  const disabled = props.disabled === undefined ? false : props.disabled;
  const readOnly = props.readOnly === undefined ? false : props.readOnly;
  const disableVerification =
    props.disableVerification === undefined ? false : props.disableVerification;

  const componentRef = useRef(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const hideFields = !!props.children;

  const preventSubmitUnlessValidated =
    props.preventSubmitUnlessValidated || false;

  const onChange = props.onChange || (d => {});

  const [internationalAddress, setInternationalAdddress] = useState(
    providedFields.internationalAddress
      ? providedFields.internationalAddress.value
      : false
  );

  const [buttonDetected, setButtonDetected] = useState(false);

  const defaultFields: Fields = buildDefaultFields(internationalAddress);

  const fields: Fields = merge(defaultFields, providedFields);

  const nameForField = useCallback(
    (field: string): string => {
      const name = fields[field].name;
      if (scope) return `${scope}[${name}]`;
      return name;
    },
    [fields, scope]
  );

  const labelForField = useCallback(
    (field: string, id: string): JSX.Element | null => {
      const text = fields[field].label;
      if (!text || text === '') return null;

      return (
        <label className={classNames({ 'txt-muted2': disabled })} htmlFor={id}>
          {text}
        </label>
      );
    },
    [fields, disabled]
  );

  const [correctedAddress, setCorrectedAddress] = useState<Address | null>(
    null
  );

  const [showModal, setShowModal] = useState(false);

  const [willSubmitForm, setWillSubmitForm] = useState(false);

  const [verified, setVerified] = useState(false);

  const [baseErrors, setBaseErrors] = useState<AddressIssue>(null);

  const [addressLine1, setAddressLine1] = useState(
    fields.addressLine1.value || ''
  );
  const [addressLine1Errors, setAddressLine1Errors] = useState<AddressIssue>(
    null
  );

  const [addressLine2, setAddressLine2] = useState(
    fields.addressLine2.value || ''
  );
  const [addressLine2Errors, setAddressLine2Errors] = useState<AddressIssue>(
    null
  );

  const [city, setCity] = useState(fields.city.value || '');
  const [cityErrors, setCityErrors] = useState<AddressIssue>(null);

  const [state, setState] = useState(fields.state.value || '');
  const [stateErrors, setStateErrors] = useState<AddressIssue>(null);

  const [zip, setZip] = useState(fields.zip.value || '');

  const defaultCountry = fields.country ? fields.country.value : '';
  const [country, setCountry] = useState(defaultCountry);

  const isVerifying = useRef(false);

  useEffect(() => {
    onChange({
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      verified,
    });
  }, [addressLine1, addressLine2, city, state, zip, verified]);

  const addressLine1Id = uniqueId('address-line-1-');
  const addressLine2Id = uniqueId('address-line-2-');
  const cityId = uniqueId('city-');
  const stateId = uniqueId('state-');
  const zipId = uniqueId('zip-');
  const countryId = uniqueId('country-');
  const internationalId = uniqueId('internation-address-');

  const containerClasses = classNames({
    stack: true,
    'stack--x-small': spacing === 'x-small',
    'stack--small': spacing === 'small',
    'stack--large': spacing === 'large',
  });

  useEffect(() => {
    if (isVerifying.current) return;
    setVerified(false);
  }, [addressLine1, addressLine2, city, state, zip, country]);

  useEffect(() => {
    if (!preventSubmitUnlessValidated) return;
    const el: any = componentRef.current;
    const form = el && el.closest('form');
    if (!form) return;

    const selectors = ['input[type="submit"]', 'button[type="submit"]']
      .map(s => prefixId(s, form.id))
      .join(', ');

    waitForElement(selectors).then((submitButton: HTMLButtonElement) => {
      submitButtonRef.current = submitButton;
      formRef.current = form;
      setButtonDetected(true);
    });
  }, []);

  const verifyAddress = useCallback(() => {
    const address: Address = {
      address: addressLine1,
      address2: addressLine2,
      locality: city,
      region: state,
      postalCode: zip,
      country: 'us',
    };

    return verify(address);
  }, [addressLine1, addressLine2, city, state, zip]);

  const providedOnSubmitCallback = useCallback(() => {
    if (disableVerification || internationalAddress) return;

    const results = verifyAddress();

    const clearErrors = () => {
      setAddressLine1Errors(null);
      setAddressLine2Errors(null);
      setCityErrors(null);
      setStateErrors(null);
      setBaseErrors(null);
      setCorrectedAddress(null);
    };

    const matchesAddress = (address: Address) => {
      if (address.address !== addressLine1) return false;
      if (address.address2 !== addressLine2) return false;
      if (address.locality !== city) return false;
      if (address.region !== state) return false;
      if (address.postalCode !== zip) return false;
      return true;
    };

    return results.then(response => {
      if (response.status === AddressStatus.Verified) {
        // If the address is verified, proceed with the submit
        clearErrors();
        setVerified(true);
        if (response.address) {
          const correctedAddress = response.address;
          setAddressLine1(correctedAddress.address);
          setAddressLine2(correctedAddress.address2);
          setCity(correctedAddress.locality);
          setState(correctedAddress.region);
          setZip(correctedAddress.postalCode);
        }

        setWillSubmitForm(true);
        return true;
      } else if (response.status === AddressStatus.Corrected) {
        // If the address was correct offer the user a choice between their
        // provided value and the  corrected value
        clearErrors();
        if (response.address) {
          if (matchesAddress(response.address)) {
            clearErrors();
            setVerified(true);
            setWillSubmitForm(true);
            return true;
          } else {
            setShowModal(true);
            setCorrectedAddress(response.address);
          }
        }
        return false;
      } else if (response.status === AddressStatus.Failed) {
        // If things have failed explain what went wrong and try to get them to fix things

        const { issues } = response;

        setShowModal(true);
        setCorrectedAddress(null);

        if (issues) {
          setAddressLine1Errors(issues.address);
          setAddressLine2Errors(issues.address2);
          setCityErrors(issues.locality);
          setStateErrors(issues.region);
          setBaseErrors(issues.base);
        } else {
          setBaseErrors([
            'Unknown address. Please verify that you have entered the correct information before proceeding',
          ]);
        }

        setVerified(false);

        return false;
      }
    });
  }, [
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    disableVerification,
    internationalAddress,
    verifyAddress,
  ]);

  const onSubmitCallback = useCallback(
    e => {
      if (disableVerification || internationalAddress) return;

      e.preventDefault();

      const results = verifyAddress();

      // Disable the submit button and show loader
      if (submitButtonRef.current) {
        submitButtonRef.current.setAttribute('disabled', 'disabled');
        const divWrapper = document.createElement('div');
        const loader = document.createElement('i');
        loader.setAttribute('class', 'fa fa-circle-o-notch fa-spin mrm');
        divWrapper.setAttribute('data-loader', 'true');

        divWrapper.appendChild(loader);

        const node =
          submitButtonRef.current.parentNode || submitButtonRef.current;

        node.insertBefore(divWrapper, submitButtonRef.current.nextSibling);
      }

      const clearErrors = () => {
        setAddressLine1Errors(null);
        setAddressLine2Errors(null);
        setCityErrors(null);
        setStateErrors(null);
        setBaseErrors(null);
        setCorrectedAddress(null);
      };

      const matchesAddress = (address: Address) => {
        if (address.address !== addressLine1) return false;
        if (address.address2 !== addressLine2) return false;
        if (address.locality !== city) return false;
        if (address.region !== state) return false;
        if (address.postalCode !== zip) return false;
        return true;
      };

      results
        .then(response => {
          if (response.status === AddressStatus.Verified) {
            // If the address is verified, proceed with the submit
            clearErrors();
            setVerified(true);

            if (response.address) {
              const correctedAddress = response.address;
              setAddressLine1(correctedAddress.address);
              setAddressLine2(correctedAddress.address2);
              setCity(correctedAddress.locality);
              setState(correctedAddress.region);
              setZip(correctedAddress.postalCode);
            }

            setWillSubmitForm(true);
          } else if (response.status === AddressStatus.Corrected) {
            // If the address was correct offer the user a choice between their
            // provided value and the  corrected value
            clearErrors();
            if (response.address) {
              if (matchesAddress(response.address)) {
                clearErrors();
                setVerified(true);
                setWillSubmitForm(true);
              } else {
                setShowModal(true);
                setCorrectedAddress(response.address);
              }
            }
          } else if (response.status === AddressStatus.Failed) {
            // If things have failed explain what went wrong and try to get them to fix things

            const { issues } = response;

            setShowModal(true);
            setCorrectedAddress(null);

            if (issues) {
              setAddressLine1Errors(issues.address);
              setAddressLine2Errors(issues.address2);
              setCityErrors(issues.locality);
              setStateErrors(issues.region);
              setBaseErrors(issues.base);
            } else {
              setBaseErrors([
                'Unknown address. Please verify that you have entered the correct information before proceeding',
              ]);
            }

            setVerified(false);
          }
        })
        .catch(e => {
          setShowModal(true);
          setCorrectedAddress(null);
          setBaseErrors(['There was an issue verifying your address']);
          setVerified(false);
        })
        .finally(() => {
          // Disable the submit button and show loader
          if (submitButtonRef.current) {
            submitButtonRef.current.removeAttribute('disabled');

            const node =
              submitButtonRef.current.parentNode || submitButtonRef.current;

            const loaderWrapper = node.querySelector('[data-loader]');
            if (loaderWrapper) loaderWrapper.remove();
          }
        });
    },
    [
      addressLine1,
      addressLine2,
      city,
      state,
      zip,
      disableVerification,
      internationalAddress,
      verifyAddress,
    ]
  );

  useEffect(() => {
    if (onSubmitForm && willSubmitForm) {
      onSubmitForm();
      return;
    }

    if (!willSubmitForm || !formRef.current) return;

    const button = submitButtonRef.current;

    if (button) {
      setWillSubmitForm(false);
      button.removeEventListener('click', onSubmitCallback);
      simulateReactMouseClick(button);
    } else {
      formRef.current.submit();
    }
  }, [willSubmitForm, onSubmitCallback, onSubmitForm]);

  useEffect(() => {
    if (!preventSubmitUnlessValidated) return;
    if (!addressLine1 && !city && !state && !zip) return;

    const submitButton = submitButtonRef.current;
    if (!submitButton || !buttonDetected) return;

    if (verified) {
      submitButton.removeEventListener('click', onSubmitCallback);
    } else {
      submitButton.addEventListener('click', onSubmitCallback);
    }

    return () => {
      submitButton.removeEventListener('click', onSubmitCallback);
    };
  }, [
    verified,
    preventSubmitUnlessValidated,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    buttonDetected,
    internationalAddress,
    onSubmitCallback,
  ]);

  const modal = () => {
    const providedAddress = (
      <AddressView
        address={addressLine1}
        address2={addressLine2}
        locality={city}
        region={state}
        postalCode={zip}
      />
    );

    const suggestedAddress = correctedAddress && (
      <AddressView
        address={wrapBoldIfDiff(addressLine1, correctedAddress.address)}
        address2={wrapBoldIfDiff(addressLine2, correctedAddress.address2)}
        locality={wrapBoldIfDiff(city, correctedAddress.locality)}
        region={wrapBoldIfDiff(state, correctedAddress.region)}
        postalCode={wrapBoldIfDiff(zip, correctedAddress.postalCode)}
      />
    );

    return (
      <Modal
        isOpen={showModal}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Shipping Address Verification Modal"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
          <h4 className="react-modal-header__title">
            Please Verify Your Shipping Address
          </h4>
          <button
            className="button-naked"
            type="button"
            onClick={() => setShowModal(false)}
          >
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        {correctedAddress && (
          <div className="stack stack--small">
            <div className="react-modal-body react-modal-content">
              <div className="notification">
                <h2 className="small-headline">You entered:</h2>
                {providedAddress}
              </div>

              <div className="notification notification--notice mbn">
                <h2 className="small-headline">We suggest:</h2>
                {suggestedAddress}
              </div>
            </div>
          </div>
        )}

        {!correctedAddress && (
          <div className="react-modal-body react-modal-content">
            <div>
              {[
                addressLine1Errors,
                addressLine2Errors,
                cityErrors,
                stateErrors,
                baseErrors,
              ].map(e => {
                if (!e || e.length < 1) return false;

                return (
                  <div className="notification notification--alert txt-em">
                    <h2 className="small-headline">
                      The following errors were detected in the address your
                      provided:
                    </h2>
                    <ul className="mbn">
                      {e.map(l => (
                        <li key={l}>{l}</li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>

            <div className="notification">
              <h2 className="small-headline">You entered:</h2>
              {providedAddress}
            </div>
          </div>
        )}

        <div className="react-modal-footer">
          <div className="react-modal-footer__cta flex-rows--wrap flex-gap-x-sm">
            <div
              className={classNames({
                'flex-rows': true,
                'flex-gap-x-sm': true,
                'flex-order-2': !correctedAddress,
              })}
            >
              {correctedAddress && (
                <button
                  onClick={() => {
                    setShowModal(false);

                    if (correctedAddress) {
                      setAddressLine1(correctedAddress.address);
                      setAddressLine2(correctedAddress.address2);
                      setCity(correctedAddress.locality);
                      setState(correctedAddress.region);
                      setZip(correctedAddress.postalCode);
                    }

                    setWillSubmitForm(true);
                  }}
                  className="button"
                  type="button"
                >
                  Use Suggested Address
                </button>
              )}

              <button
                onClick={() => {
                  setWillSubmitForm(true);
                }}
                className={classNames({
                  button: !!correctedAddress,
                  'button--secondary': !!correctedAddress,
                  'button-naked': !correctedAddress,
                })}
                type="button"
              >
                Keep Address As Is
              </button>
            </div>

            <button
              type="button"
              className={classNames({
                mla: !!correctedAddress,
                button: !correctedAddress,
                'button-naked': correctedAddress,
                'flex-order-1': !correctedAddress,
              })}
              onClick={() => setShowModal(false)}
            >
              {correctedAddress ? 'Cancel' : 'Cancel and Fix Address'}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div ref={componentRef} className={containerClasses}>
      {props.allowInternational && props.allowInternationalToggle && (
        <label htmlFor={internationalId}>
          <span className="mrm">
            <Checkbox
              type="checkbox"
              checked={internationalAddress}
              name={nameForField('internationalAddress')}
              id={internationalId}
              onChange={e => {
                const checked = e.target.checked;
                if (checked && country === 'United States') setCountry('');
                // if (!checked && !!state) setState('');
                setInternationalAdddress(checked);
              }}
            />
          </span>
          International address?
        </label>
      )}

      <div className="stack stack--small">
        {baseErrors && (
          <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
            {baseErrors}
          </div>
        )}

        {hideFields && props.children}

        {!internationalAddress && !hideFields && (
          <>
            <div className="stack stack--x-small">
              {labelForField('addressLine1', addressLine1Id)}
              {addressLine1Errors && (
                <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
                  {addressLine1Errors}
                </div>
              )}
              <input
                type="text"
                placeholder="Street address"
                id={addressLine1Id}
                value={addressLine1}
                disabled={disabled}
                readOnly={readOnly}
                name={nameForField('addressLine1')}
                onChange={e => setAddressLine1(e.target.value)}
                className={classNames({
                  readonly: readOnly,
                  'field-error': !!addressLine1Errors,
                })}
              />
            </div>
          </>
        )}
        {!internationalAddress && hideFields && (
          <input
            type="hidden"
            value={addressLine1}
            name={nameForField('addressLine1')}
          />
        )}

        {internationalAddress && !hideFields && (
          <div className="stack stack--x-small">
            {labelForField('addressLine1', addressLine1Id)}
            {addressLine1Errors && (
              <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
                {addressLine1Errors}
              </div>
            )}
            <input
              type="text"
              placeholder="Street address"
              id={addressLine1Id}
              value={addressLine1}
              disabled={disabled}
              readOnly={readOnly}
              name={nameForField('addressLine1')}
              onChange={e => setAddressLine1(e.target.value)}
              className={classNames({
                readonly: readOnly,
                'field-error': !!addressLine1Errors,
              })}
            />
          </div>
        )}

        {internationalAddress && hideFields && (
          <input
            type="hidden"
            value={addressLine1}
            name={nameForField('addressLine1')}
          />
        )}

        {!hideFields && (
          <div className="stack stack--x-small">
            {labelForField('addressLine2', addressLine2Id)}
            {addressLine2Errors && (
              <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
                {addressLine2Errors}
              </div>
            )}
            <input
              type="text"
              placeholder="Apt, suite, unit, building, floor, etc."
              id={addressLine2Id}
              value={addressLine2}
              disabled={disabled}
              readOnly={readOnly}
              name={nameForField('addressLine2')}
              onChange={e => setAddressLine2(e.target.value)}
              className={classNames({
                readonly: readOnly,
                'field-error': !!addressLine2Errors,
              })}
            />
          </div>
        )}

        {hideFields && (
          <input
            type="hidden"
            value={addressLine2}
            name={nameForField('addressLine2')}
          />
        )}
      </div>

      {!hideFields && (
        <div className="stack stack--x-small">
          {labelForField('city', cityId)}
          {cityErrors && (
            <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
              {cityErrors}
            </div>
          )}
          <input
            id={cityId}
            type="text"
            value={city}
            disabled={disabled}
            placeholder="City"
            readOnly={readOnly}
            name={nameForField('city')}
            onChange={e => setCity(e.target.value)}
            className={classNames({
              readonly: readOnly,
              invalid: !!cityErrors,
            })}
          />
        </div>
      )}
      {hideFields && (
        <input type="hidden" value={city} name={nameForField('city')} />
      )}

      {!internationalAddress && !hideFields && (
        <div className="label-select stack stack--x-small">
          {labelForField('state', stateId)}
          {stateErrors && (
            <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
              {stateErrors}
            </div>
          )}
          <select
            id={stateId}
            value={state}
            name={nameForField('state')}
            disabled={disabled || readOnly}
            onChange={e => setState(e.target.value)}
            className={classNames({
              invalid: !!stateErrors,
              readonly: readOnly,
            })}
          >
            <option value="">Choose a State</option>
            {states.map(({ abbreviation, name }) => (
              <option key={abbreviation} value={abbreviation}>
                {name}
              </option>
            ))}
          </select>
          {readOnly && (
            <input type="hidden" value={state} name={nameForField('state')} />
          )}
        </div>
      )}

      {!internationalAddress && hideFields && (
        <input type="hidden" value={state} name={nameForField('state')} />
      )}

      {internationalAddress && !hideFields && (
        <div className="stack stack--x-small">
          {labelForField('state', stateId)}
          <input
            id={stateId}
            type="text"
            value={state}
            disabled={disabled}
            readOnly={readOnly}
            placeholder="Region"
            name={nameForField('state')}
            onChange={e => setState(e.target.value)}
            className={classNames({
              readonly: readOnly,
              invalid: !!stateErrors,
            })}
          />
        </div>
      )}

      {internationalAddress && hideFields && (
        <input type="hidden" value={state} name={nameForField('state')} />
      )}

      {!hideFields && (
        <div className="stack stack--x-small">
          {labelForField('zip', zipId)}
          <input
            id={zipId}
            type="text"
            value={zip}
            name={nameForField('zip')}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={fields['zip'].label || 'Zip'}
            onChange={e => setZip(e.target.value)}
            className={classNames({
              readonly: readOnly,
            })}
          />
        </div>
      )}
      {hideFields && (
        <input type="hidden" value={zip} name={nameForField('zip')} />
      )}

      {internationalAddress && !hideFields && (
        <div className="stack stack--x-small">
          {labelForField('country', countryId)}
          <input
            id={countryId}
            type="text"
            value={country}
            name={nameForField('country')}
            disabled={disabled}
            onChange={e => setCountry(e.target.value)}
          />
        </div>
      )}
      {internationalAddress && hideFields && (
        <input type="hidden" value={country} name={nameForField('country')} />
      )}

      {!internationalAddress && (
        <input
          type="hidden"
          value="United States"
          name={nameForField('country')}
        />
      )}

      {modal()}

      {renderSubmit && renderSubmit(providedOnSubmitCallback)}
    </div>
  );
};
