import dropin from 'braintree-web-drop-in';
import { hiddenBy, shownBy } from './hiddenBy';

const initBasementNav = () => {
  let selector = ['.js-menu-trigger', '.js-menu-screen'].join(',');

  let mobileSelector = ['.js-menu', '.js-menu-screen'].join(',');

  let clickables = document.querySelectorAll(selector);
  let mobileElements = document.querySelectorAll(mobileSelector);
  let wrap = document.querySelector('.l-site-wrapper');

  for (let i = 0; i < clickables.length; ++i) {
    clickables[i].addEventListener('click', () => {
      wrap.classList.toggle('l-site-wrapper--open');
      for (let j = 0; j < mobileElements.length; ++j) {
        mobileElements[j].classList.toggle('is-visible');
      }
    });
  }
};

const initCheckoutNav = () => {
  let selector = ['.bb-menu-trigger', '.bb-menu-screen'].join(',');

  let mobileSelector = ['.bb-menu', '.bb-menu-screen'].join(',');

  let clickables = document.querySelectorAll(selector);
  let mobileElements = document.querySelectorAll(mobileSelector);
  let wrap = document.querySelector('.bb-site-wrapper');

  for (let i = 0; i < clickables.length; ++i) {
    clickables[i].addEventListener('click', () => {
      wrap.classList.toggle('bb-site-wrapper--open');
      for (let j = 0; j < mobileElements.length; ++j) {
        mobileElements[j].classList.toggle('is-visible');
      }
    });
  }
};

const braintreePaymentOptions = () => {
  let forms = document.querySelectorAll('[data-braintree-form]');
  forms.forEach(form => {
    let nonceInput = form.querySelector('#nonce-input');
    let submitButton = form.querySelector('#submit-button');
    let clientToken = form.getAttribute('data-client-token');
    let profileID = form.getAttribute('data-venmo-key');

    if (submitButton.classList) {
      submitButton.classList.add('is-disabled');
    } else {
      submitButton.className += ' ' + 'is-disabled';
    }

    dropin.create(
      {
        authorization: clientToken,
        container: '#payment-form',
        card: {
          cardholderName: {
            required: true,
          },
        },
        venmo: {
          allowNewBrowserTab: true,
          profileId: profileID,
        },
        paypal: { flow: 'vault' },
      },
      function(createErr, instance) {
        instance.on('paymentMethodRequestable', function() {
          if (submitButton.classList) {
            submitButton.classList.remove('is-disabled');
          } else {
            submitButton.className = submitButton.className.replace(
              new RegExp(
                '(^|\\b)' + 'is-disabled'.split(' ').join('|') + '(\\b|$)',
                'gi'
              ),
              ' '
            );
          }
          const invoiceOption = document.getElementById(
            'payment_order_invoice_requested_true'
          );
          if (invoiceOption && invoiceOption.checked) {
            $('.braintree-heading').text('Backup Payment Method');
          } else if (invoiceOption) {
            $('.braintree-heading').text('Payment Methods');
          }
        });

        instance.on('noPaymentMethodRequestable', function() {
          if (submitButton.classList) {
            submitButton.classList.add('is-disabled');
          } else {
            submitButton.className += ' ' + 'is-disabled';
          }
        });

        form.addEventListener('submit', function(event) {
          event.preventDefault();
          instance.requestPaymentMethod(function(
            requestPaymentMethodErr,
            payload
          ) {
            if (requestPaymentMethodErr) {
              setTimeout(function() {
                submitButton.disabled = false;
              }, 100);
              return;
            }

            nonceInput.value = payload.nonce;
            form.submit();
          });
        });
      }
    );
  });
};

const eventListenerArtJobView = () => {
  let isArtJobsView = document.getElementById('art-jobs-detail') !== null;
  if (isArtJobsView) {
    document.addEventListener('click', function(event) {
      if (!event.target.matches('#art-due-date-edit-button')) return;
      let artJobId = event.target.getAttribute('data-art-job');
      let artDueDateWrapper = document.getElementById(
        `art-due-date-${artJobId}`
      );
      artDueDateWrapper.classList.toggle('editing');
      event.target.classList.toggle('button--secondary');
      event.target.classList.toggle('button--outline');
      event.target.innerText = artDueDateWrapper.classList.contains('editing')
        ? 'Done'
        : 'Edit';
    });
  }
};

// NOTE: This is a fix for using position sticky on a container with an overflow
// property set. See the CM sales dashboard for an example of this in action.
// BD 4/9/2021
const scrollSync = function() {
  const scroll = 'scroll';

  let names = {};
  const elems = document.getElementsByClassName('sync' + scroll);

  // clearing existing listeners
  let i, j, el, found, name;
  for (name in names) {
    if (names.hasOwnProperty(name)) {
      for (i = 0; i < names[name].length; i++) {
        names[name][i].removeEventListener(scroll, names[name][i].syn, 0);
      }
    }
  }

  function createOnScrollHandler(el) {
    return function() {
      let elems = names[name];

      let scrollX = el.scrollLeft;
      let scrollY = el.scrollTop;

      let xRate = scrollX / (el.scrollWidth - el.clientWidth);
      let yRate = scrollY / (el.scrollHeight - el.clientHeight);

      let updateX = scrollX !== el.eX;
      let updateY = scrollY !== el.eY;

      let otherEl,
        i = 0;

      el.eX = scrollX;
      el.eY = scrollY;

      for (; i < elems.length; ) {
        otherEl = elems[i++];
        if (otherEl !== el) {
          if (
            updateX &&
            Math.round(
              otherEl.scrollLeft -
                (scrollX = otherEl.eX = Math.round(
                  xRate * (otherEl.scrollWidth - otherEl.clientWidth)
                ))
            )
          ) {
            otherEl.scrollLeft = scrollX;
          }

          if (
            updateY &&
            Math.round(
              otherEl.scrollTop -
                (scrollY = otherEl.eY = Math.round(
                  yRate * (otherEl.scrollHeight - otherEl.clientHeight)
                ))
            )
          ) {
            otherEl.scrollTop = scrollY;
          }
        }
      }
    };
  }

  // setting-up the new listeners
  for (i = 0; i < elems.length; ) {
    found = j = 0;
    el = elems[i++];
    if (!(name = el.getAttribute('name'))) {
      // name attribute is not set
      continue;
    }

    el = el[scroll + 'er'] || el; // needed for instance

    // searching for existing entry in array of names;
    // searching for the element in that entry
    for (; j < (names[name] = names[name] || []).length; ) {
      found |= names[name][j++] === el;
    }

    if (!found) {
      names[name].push(el);
    }

    el.eX = el.eY = 0;

    el.syn = createOnScrollHandler(el);

    el.addEventListener(scroll, el.syn, 0);
  }
};

const actionNoteFormToggle = () => {
  const actionTextForms = document.querySelectorAll('.action-text-form');

  if (actionTextForms.length > 0) {
    for (const actionTextForm of actionTextForms) {
      const buttonCollection = actionTextForm.querySelectorAll(
        '.action-note-button'
      );
      for (const button of buttonCollection) {
        button.addEventListener('click', function() {
          const stringId = button.getAttribute('data');

          if (stringId) {
            const note = actionTextForm.querySelector(
              `#action-note-${stringId}`
            );
            const form = actionTextForm.querySelector(
              `#action-note-form-${stringId}`
            );
            const cancelLink = actionTextForm.querySelector(
              `#action-note-form-cancel-link-${stringId}`
            );

            if (form) {
              button.classList.add('is-hidden-visually');
              note.classList.add('is-hidden-visually');
              form.classList.remove('is-hidden-visually');
            }

            if (cancelLink) {
              cancelLink.addEventListener('click', function() {
                button.classList.remove('is-hidden-visually');
                note.classList.remove('is-hidden-visually');
                form.classList.add('is-hidden-visually');
              });
            }
          }
        });
      }
    }
  }
};

// Bird Bank Signup Line Item Size Select Filter
const birdBankItemSizeSelectFilter = () => {
  const newSignupForm = document.querySelector('.new-signup-line-item-form');

  if (newSignupForm) {
    const itemSelector = newSignupForm.querySelector('.bird-bank-item-select');
    const initialSizes = newSignupForm.querySelector('.size-select');
    const optGroups = initialSizes.querySelectorAll('optgroup');

    itemSelector.addEventListener('change', () => {
      const birdBankItemID =
        itemSelector.options[itemSelector.selectedIndex].value;

      const sizeSelect = Array.from(optGroups).filter(list => {
        return list.matches(
          `optgroup[data-bird-bank-item-id="${birdBankItemID}"]`
        );
      });

      if (sizeSelect.length > 0) {
        initialSizes.innerHTML = sizeSelect[0].outerHTML;
      } else {
        initialSizes.innerHTML = '';
      }
    });
  }
};

let init = () => {
  initBasementNav();
  hiddenBy();
  shownBy();
  braintreePaymentOptions();
  eventListenerArtJobView();
  initCheckoutNav();
  scrollSync();
  actionNoteFormToggle();
  birdBankItemSizeSelectFilter();
};

export default init;
