import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchPrintColorOptions } from '../stores/global-data';
import { find } from 'lodash';

const cmykLabel = () => (
  <div className="color-preview color-preview-rounded color-preview-cmyk mbn">
  </div>
);

class PrintColorsRenderer extends Component {

  static propTypes = {
    value: PropTypes.array,
    order_id: PropTypes.number,
    decoration_type: PropTypes.string
  }

  componentWillMount() {
    fetchPrintColorOptions(this.props.order_id).then(() => {
      this.setState({
        printColorsLoaded: true
      });
    });
  }

  renderValue() {
    if (!window.GlobalData.allDecorationColorOptions) return;

    let decorationType = this.props.decoration_type === 'custom_tag' ?
      'screen_print' : this.props.decoration_type;
    return this.props.value.map(colorId => {
      let matchedPrintColor = find(
        window.GlobalData.allDecorationColorOptions,
        o => (
          o.value === colorId.toString() &&
            o.decoration_type === decorationType
        )
      );

      let icon, text;

      if (matchedPrintColor) {
        const hexLabel = (
          <i
            className="fa fa-tint fa-fw"
            style={{ color: `#${matchedPrintColor.hex}` }}
          ></i>
        );

        icon = matchedPrintColor.label == 'CMYK' ? cmykLabel : hexLabel;
        text = matchedPrintColor.label;
      } else {
        icon = <i className="fa fa-plus fa-fw"></i>;
        text = `PMS(${colorId})`;
      }

      return (
        <li className="txt-nowrap" key={`print-color-${colorId}`}>
          {icon}
          {text}
        </li>
      );
    });

  }

  render() {
    return <span>{this.renderValue()}</span>;
  }

}

export default PrintColorsRenderer;
