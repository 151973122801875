import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ButtonDropdown from '../ButtonDropDown';
import Icon from '../Icon';
import { formatPhoneNumber } from '../../PhoneHelper';
import { compact, every, find, filter as _filter, some } from 'lodash';
import { useToggle } from '../custom-hooks';
import SlimLoader from '../SlimLoader';
import Select from 'react-select';

const SearchForm = ({
  searchableFields,
  defaultParams,
  passThroughParams,
  searchParams,
  hideFilters,
  filterParams,
  filterDomId,
  searchScopes,
}) => {
  const allowedFacets = searchableFields.map(({ name }) => name);

  const [searchScopeValues, setSearchScopeValues] = useState(
    searchScopes && searchScopes.map(({ field, value }) => ({ field, value }))
  );
  const selectedSearchParam = useMemo(
    () => find(searchParams, ({ name }) => allowedFacets.includes(name)) || {},
    [searchParams, searchableFields]
  );

  const [facet, setFacet] = useState(
    selectedSearchParam['name'] || allowedFacets[0]
  );

  const filterDomNode = useMemo(
    () => filterDomId && document.getElementById(filterDomId),
    [filterDomId]
  );

  const [submitting, toggleSubmitting] = useToggle(false);

  const [dirty, toggleDirty] = useToggle(false);

  const originalTerm = useMemo(() => {
    return selectedSearchParam['value'] || '';
  }, [selectedSearchParam]);

  const [term, setTerm] = useState(originalTerm || '');

  const inputRef = useRef(null);
  const formRef = useRef(null);

  const [filters, setFilters] = useState(filterParams);

  const handleInputChange = e => {
    let value = e.target.value;

    if (facet === 'phone') value = formatPhoneNumber(value);

    setTerm(value);
  };

  const handleFormSubmit = e => {
    if (!submitting) {
      e.preventDefault();
    }

    if (term !== originalTerm) clearStatusFilter();
    else submitForm();
  };

  const submitForm = () => {
    toggleSubmitting(true);
    formRef.current.submit();
  };

  const facetData = searchableFields.map(({ name, label }) => ({
    href: '',
    onClick: e => {
      e.preventDefault();
      setFacet(name);

      inputRef.current && inputRef.current.focus();
    },
    label: label,
    active: name === facet,
    key: `${name}-${label}`,
  }));

  const selectedInput = find(searchableFields, { name: facet });

  const { inputAttributes } = selectedInput || {};

  let inputProperties = {
    ...inputAttributes,
    value: term,
    name: `search[${facet}]`,
    onChange: handleInputChange,
  };

  switch (facet) {
    case 'phone':
      inputProperties = {
        ...inputProperties,
        type: 'tel',
        pattern: '\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}',
        required: 'true',
        placeholder: '(###) ###-####',
        minlength: 14,
      };
      break;
    default:
      inputProperties['type'] = 'text';
      break;
  }

  const filterOptions =
    filters &&
    !filterDomNode &&
    filters.map(
      ({ label, field, value, active }) =>
        !active && {
          href: '#',
          label,
          key: `${label}-${value}`,
          onClick: e => {
            e.preventDefault();

            setFilters(
              filters.map(filter => {
                if (filter['field'] === field && filter['value'] === value)
                  return { ...filter, active: true };
                else if (
                  filter['field'] === field &&
                  field.endsWith('[]') &&
                  (value == null || filter['value'] == null)
                )
                  return { ...filter, active: false };
                else if (
                  filter['field'] === field &&
                  !field.endsWith('[]') &&
                  value !== filter['value']
                )
                  return { ...filter, active: false };
                else return filter;
              })
            );

            toggleDirty(true);
          },
        }
    );

  const updateSearchScopeValue = (field, values) => {
    setSearchScopeValues(
      searchScopeValues.map(searchScopeValue => {
        if (searchScopeValue.field !== field) return searchScopeValue;

        return { ...searchScopeValue, value: values.map(({ value }) => value) };
      })
    );

    toggleDirty(true);
  };

  const portalFilterOptions = filters && filterDomNode && (
    <>
      {searchScopes &&
        searchScopes.map(({ label, field, options }) => {
          const value = find(searchScopeValues, { field });
          return (
            <div className="order-filter" key={`${label}-${value.value}`}>
              <h3>{label}</h3>
              <Select
                onChange={e => updateSearchScopeValue(field, e)}
                options={options}
                value={value.value}
                multi
                disabled={submitting}
              />
            </div>
          );
        })}
      <div className="order-filter">
        <h3>Filters</h3>
        <nav
          className={classNames('order-filters-list', {
            'is-disabled': submitting,
          })}
        >
          <ul className="order-sidebar-nav-ul" id="order-sidebar">
            {filters.map(({ label, counts, field, value, active }) => (
              <li key={`${label}-${value}`}>
                <a
                  href="#"
                  className={classNames({
                    active,
                    disabled: submitting,
                    'filters-grid': true,
                  })}
                  onClick={e => {
                    e.preventDefault();

                    setFilters(
                      filters.map(filter => {
                        if (
                          filter['field'] === field &&
                          filter['value'] === value
                        )
                          return { ...filter, active: !active };
                        else if (
                          filter['field'] === field &&
                          (value === null || filter['value'] === null)
                        )
                          return { ...filter, active: false };
                        else return filter;
                      })
                    );

                    toggleDirty(true);
                  }}
                >
                  <i className="fa fa-times-circle grid-item--center-v order-filter-close-icon" />
                  <div className="order-label grid-item--center-v">
                    {label}{' '}
                  </div>
                  {counts && counts.show && (
                    <div className="count-wrapper">
                      <div
                        className={classNames({
                          label: true,
                          'label--success': label !== 'Blocked',
                          'label--alert': label === 'Blocked',
                          count: true,
                          'grid-item--end': true,
                        })}
                      >
                        {counts.count}
                      </div>
                    </div>
                  )}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );

  const activeFilters = _filter(filters, { active: true });

  useEffect(() => {
    if (!dirty) return;

    submitForm();
  }, [dirty]);

  const clearStatusFilter = () => {
    toggleDirty(true);
    if (!filters) return;
    setFilters(
      filters.map(filter => {
        if (filter['field'] === 'status[]' && filter['value'] == null)
          return { ...filter, active: true };
        else if (filter['field'] === 'status[]')
          return { ...filter, active: false };
        else return filter;
      })
    );
  };

  const onlyDefaultParameters = () => {
    return every(activeFilters, ({ field, value }) =>
      isDefaultParameter({ field, value })
    );
  };

  const isDefaultParameter = ({ field, value }) => {
    const field_name = field.replace('search[', '').replace(']', '');
    return some(defaultParams, { name: field_name, value: value });
  };

  const scopeHiddenFields =
    searchScopes &&
    searchScopeValues.map(({ field, value }) =>
      value.map(fieldValue => (
        <input
          key={`${field}-${fieldValue}`}
          type="hidden"
          name={field}
          value={fieldValue}
        />
      ))
    );

  const filterHiddenFields =
    filters &&
    activeFilters.map(({ label, field, value }) => {
      if (onlyDefaultParameters() && isDefaultParameter({ field, value }))
        return null;

      return (
        <input key={label} type="hidden" name={field} value={value || ''} />
      );
    });

  const hiddenPassThroughFields =
    passThroughParams &&
    passThroughParams.map(({ name, value }) => (
      <input type="hidden" value={value} name={name} key={name} />
    ));

  const filterBar =
    filters &&
    activeFilters.map(({ label, field, value }) => (
      <React.Fragment key={label}>
        <a
          href="#"
          className="label label--filter mas"
          onClick={e => {
            e.preventDefault();

            setFilters(
              filters.map(filter => {
                if (filter['field'] === field && filter['value'] === value)
                  return { ...filter, active: false };
                else return filter;
              })
            );

            toggleDirty(true);
          }}
        >
          {label} <i className="fa fa-times-circle mll" />
        </a>
      </React.Fragment>
    ));

  return (
    <form
      className={classNames(
        'form-light',
        'form-light--bordered',
        'form-compact',
        { 'is-disabled': submitting }
      )}
      ref={formRef}
      onSubmit={handleFormSubmit}
    >
      <div className="grid-row mvl">
        <div className="grid-col-8">
          <div className="search-focus-container">
            <input
              type="text"
              {...inputProperties}
              value={term}
              className={classNames('search-focus', {
                'is-disabled': submitting,
              })}
              ref={inputRef}
            />
            <button type="submit" className="search-focus__button">
              <span className="is-hidden-visually">Search</span>
              {submitting ? (
                <SlimLoader />
              ) : (
                <Icon type="search" color="#444" />
              )}
            </button>
            <ButtonDropdown
              items={facetData}
              buttonSearchInput
              dropdownBottom
              dropdownLeft
              type="button"
            />
          </div>
        </div>
        {filters &&
          filterDomNode &&
          ReactDOM.createPortal(portalFilterOptions, filterDomNode)}
        {!hideFilters && filters && !filterDomNode && (
          <div className="grid-col-4 flex-rows flex-rows--end flex-rows--collapse">
            <ButtonDropdown
              buttonText="Filters"
              buttonIcon="filter"
              buttonSecondary
              dropdownBottom
              dropdownRightCollapse
              items={compact(filterOptions)}
              linkAttributes={{ title: 'Filters', target: '_self' }}
            />
          </div>
        )}
      </div>
      {filters && filterHiddenFields}
      {scopeHiddenFields}
      {hiddenPassThroughFields}
      {!hideFilters && filters && !filterDomNode && (
        <div className="filterBar flex-rows flex-rows--wrap mbm">
          {filterBar}
        </div>
      )}
    </form>
  );
};

SearchForm.propTypes = {
  searchParams: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  searchableFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      inputProperties: PropTypes.object,
    })
  ),
  filterDomId: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string,
      value: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};

export default SearchForm;
