import React from 'react';
import { formatMoney, formatPercent } from '../utility';
import Loader from './loader';
import classNames from 'classnames';

const OrderPriceBreakdown = props => {
  const { order } = props;

  if (!order) return <Loader />;

  const totalGrossProfit = order.totalGrossProfit ? (
    <h3 className="mvs headline-sans total-calculation--res total-gross-profit">
      <span className="txt-muted2">Total Gross Profit</span>
      <div className="total-value--res">
        {formatMoney(order.totalGrossProfit)}
      </div>
    </h3>
  ) : null;

  const totalActualProfitMargin = order.totalActualProfitMargin ? (
    <h3 className="mvs headline-sans total-calculation--res total-actual-profit-margin">
      <span className="txt-muted2">Total Profit Margin</span>
      <div className="total-value--res">
        {formatPercent(order.totalActualProfitMargin)}
      </div>
    </h3>
  ) : null;

  const totalQuantity = (
    <h3 className="mvs headline-sans total-calculation--res total-quantity">
      <span className="txt-muted2">{`Items (${order.totalQuantity})`}</span>
      <div className="total-value--res">{formatMoney(order.subtotal)}</div>
    </h3>
  );

  const salesTaxRate = order.hasVariableTaxRate
    ? 'rate varies'
    : formatPercent(order.salesTaxRate);

  const estimatedSalesTax =
    order.subjectToTax && order.showSalesTax ? (
      <h3 className="mvs headline-sans total-calculation--res estimated-sales-tax">
        <span className="txt-muted2">{`Est. Sales Tax (${salesTaxRate})`}</span>
        <div className="total-value--res">{formatMoney(order.salesTax)}</div>
      </h3>
    ) : null;

  const totalShipping = order.totalShipping ? (
    <h3 className="mvs headline-sans total-calculation--res estimated-sales-tax">
      <span className="txt-warning">Rush Fee</span>
      <div className="total-value--res txt-warning">
        {formatMoney(order.totalShipping)}
      </div>
    </h3>
  ) : null;

  const totalClassName = classNames(
    'mtl',
    'txt-strong',
    'tertiary-headline',
    'headline-sans',
    'total-calculation--res'
  );

  const totalView = (
    <h2 className={totalClassName}>
      Total Sale
      <div className="total-value--res">{formatMoney(order.totalSale)}</div>
    </h2>
  );

  return (
    <React.Fragment>
      {totalGrossProfit}
      {totalActualProfitMargin}
      {totalQuantity}
      {totalShipping}
      {estimatedSalesTax}
      {totalView}
    </React.Fragment>
  );
};

export default OrderPriceBreakdown;
