import React, { useCallback, useContext } from 'react';
import ArtProofUploader from './ArtProofUploader';
import * as Types from '../../base-types';
import Revisions from './Revisions';
import ColorSelect from '../ColorSelect';
import {
  ArtSubmissionContext,
  Location as DecoratedLocation,
} from './ArtSubmissionContext';
import DecorationStyleSelect from '../DecorationStyleSelect';
import {
  labelForDecorationType,
  labelForDecorationTypeColor,
} from '../../labels';
import LightBox from '../LightBox';
import ErrorBox from '../ErrorBox';
import PostsApp from '../PostsApp';
import TextArea from '../TextArea';
import ColorsItem from '../ColorItem';
import AutoSaveStatus from '../AutoSaveStatus';
import IconSvg from '../IconSvg';
import { Color } from '../../BrandColor';

function dumbPluralize(word: string, count: number): string {
  const suffix: string = count === 1 ? '' : 's';
  return `${count} ${word}${suffix}`;
}

interface Props {
  location: DecoratedLocation;
  directUploadUrl: string;
}

export default function Location(props: Props) {
  const { location } = props;
  const { dispatch } = useContext(ArtSubmissionContext);

  const colorsChanged = (payload: Types.DecorationColor[]) => {
    dispatch({
      type: 'update-location-colors',
      id: location.id,
      payload,
    });
  };

  const didUpdate = useCallback(
    revisions => {
      dispatch({
        type: 'update-location-revisions',
        id: location.id,
        payload: revisions,
      });
    },
    [location.id, dispatch]
  );

  const styleChanged = (payload: Types.DecorationStyle) => {
    dispatch({
      type: 'update-location-style',
      id: location.id,
      payload,
    });
  };

  const decorationOptionsUI = () => {
    const options = location.decorationOptions;
    if (!options) return null;
    if (options.kind === 'tackle-twill') {
      return (
        <div className="stack">
          <div>
            {dumbPluralize('letter', options.numberOfLetters)},{' '}
            {dumbPluralize('layer', options.numberOfLayers)}
          </div>
          <div>
            <h4 className="better-label">Foreground:</h4>
            <ul className="list-plain">
              <li>
                <strong>Fabric</strong>: {options.foreground.fabricName}
              </li>
              <li>
                <strong>Thread</strong>: {options.foreground.threadName}
              </li>
              <li>
                <strong>Stitch</strong>: {options.foreground.stitchType}
              </li>
            </ul>
          </div>
          {options.background && (
            <div>
              <h4 className="better-label">Background:</h4>
              <ul className="list-plain">
                <li>
                  <strong>Fabric</strong>: {options.background.fabricName}
                </li>
                <li>
                  <strong>Thread</strong>: {options.background.threadName}
                </li>
                <li>
                  <strong>Stitch</strong>: {options.background.stitchType}
                </li>
              </ul>
            </div>
          )}
        </div>
      );
    } else if (options.kind === 'heat-press') {
      return (
        <div>
          {options.letteringStyle} &mdash; {options.type}
        </div>
      );
    } else if (options.kind === 'flocking') {
      return (
        <div>
          <ul className="list-plain">
            <li>
              <strong>Size</strong>: {options.size}
            </li>
            <li>
              <strong>Flock Colors</strong>:{' '}
              {options.flockColors.map(c => (
                <ColorsItem key={c.id} color={c} />
              ))}
            </li>
            <li>
              <strong>Vinyl Colors</strong>:{' '}
              {options.vinylColors.map(c => (
                <ColorsItem key={c.id} color={c} />
              ))}
            </li>
          </ul>
        </div>
      );
    } else if (options.kind === 'embroidery') {
      const id = `location-${location.id}-thread-count`;
      return (
        <div>
          <label htmlFor={id}>Stitch Count</label>
          {location.permissions.threadCount === Types.Permissions.ReadOnly ? (
            <span>{location.threadCount}</span>
          ) : (
            <input
              type="number"
              id={id}
              value={location.threadCount}
              onChange={e => {
                dispatch({
                  type: 'update-location-thread-count',
                  id: location.id,
                  payload: e.target.value,
                });
              }}
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`location-${location.id} l-section--bordered pan`}>
      <div className="location__divider location__header">
        <AutoSaveStatus saveStatus={location.saveStatus} />
        <ErrorBox errors={location.errors} />

        <h3 className="mvn">
          {location.location}{' '}
          <span className="headline-supplement">
            {labelForDecorationType(location.decorationType)}
          </span>
        </h3>
      </div>
      <div className="location__section location__section--3 location__divider">
        <div className="location__component">
          <p className="better-label">Proof</p>

          <ArtProofUploader
            location={location}
            directUploadUrl={props.directUploadUrl}
            acceptedMIMETypes={
              location.attachmentValidation.allowedContentTypes
            }
            maxSize={location.attachmentValidation.maxSize}
          />
        </div>
        <div className="location__section location__section--2">
          <div className="location__component stack">
            {location.decorationType !== Types.DecorationType.TackleTwill && (
              <div>
                <label htmlFor="ink-colors" className="better-label">
                  {labelForDecorationTypeColor(location.decorationType)}
                </label>

                <ColorSelect
                  decorationType={location.decorationType}
                  value={location.colors}
                  allowCustom={true}
                  onChange={colorsChanged}
                  readonly={
                    location.permissions.colors === Types.Permissions.ReadOnly
                  }
                />
              </div>
            )}

            <div>
              <label htmlFor="style" className="better-label">
                Style
              </label>

              <div className="label-select">
                <DecorationStyleSelect
                  id="style"
                  value={location.decorationStyle}
                  onChange={styleChanged}
                  location={location}
                  readonly={
                    location.permissions.decorationStyle ===
                    Types.Permissions.ReadOnly
                  }
                />
              </div>
            </div>

            {location.decorationOptions && (
              <div>
                <h3 className="better-label">Decoration Options</h3>

                <div className="label-select mbl">{decorationOptionsUI()}</div>
              </div>
            )}
          </div>
          <div className="location__component stack">
            <div>
              {location.description && (
                <>
                  <h3 className="better-label">Description</h3>
                  <p
                    className="mvn"
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {location.description}
                  </p>
                </>
              )}

              {location.artFiles.length > 0 && (
                <div>
                  {location.artFiles.map(
                    (artFile, i) =>
                      artFile.previewUrls.thumbnail && (
                        <div
                          className="flex-rows flex-rows--center-v mbs"
                          key={i}
                        >
                          <LightBox src={artFile.previewUrls.fullsize}>
                            <img
                              src={artFile.previewUrls.thumbnail}
                              alt=""
                              width="30"
                            />
                          </LightBox>
                          <span
                            className="attachment-title mhm"
                            title={artFile.filename}
                          >
                            {artFile.filename}
                          </span>
                          <a href={artFile.url}>
                            <IconSvg
                              icon="download"
                              color={Color.TealBlue}
                              size={24}
                            />
                          </a>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="style" className="better-label">
                Production Notes
              </label>

              <div className="label-select mbl">
                {location.permissions.productionNotes ===
                Types.Permissions.ReadOnly ? (
                  location.productionNotes ? (
                    <>{location.productionNotes}</>
                  ) : (
                    <p>
                      <em className="txt-muted2">
                        There are currently no production notes.
                      </em>
                    </p>
                  )
                ) : (
                  <TextArea
                    id="style"
                    value={location.productionNotes || ''}
                    onChange={e => {
                      dispatch({
                        type: 'update-location-production-notes',
                        id: location.id,
                        payload: e.target.value,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="location__section location__section--2">
        <div className="location__component location-revisions">
          <Revisions
            directUploadUrl={props.directUploadUrl}
            location={location}
            initialRevisions={location.revisions}
            didUpdate={didUpdate}
          />
        </div>
        <div className="location__component bg-light-blue">
          <h3 className="mtm mbs better-label">Comments</h3>
          <PostsApp
            postableType="Location"
            postableId={location.id}
            postableName="artComments"
          />
        </div>
      </div>
    </div>
  );
}
