import PropTypes from 'prop-types';
import React from 'react';
import ItemForm from './item-form';
import tenant from '../tenant';
import { debounce } from 'lodash';

class Item extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    products: PropTypes.array,
    hideCommissioningData: PropTypes.bool,
    fullAccess: PropTypes.bool,
  };

  state = {
    isEditing: false,
  };

  updateActual = e => {
    let actual = e.target.value;
    let item = { ...this.props.item, actual };
    this.props.onUpdate(item);
  };

  onActualChange = e => {
    let className = 'invalid';
    let actual = parseFloat(e.target.value);
    let el = e.target;

    if (actual < this.minimumPrice()) {
      if (el.classList) el.classList.add(className);
      else el.className += ' ' + className;
    } else {
      if (el.classList) {
        el.classList.remove(className);
      } else {
        el.className = el.className.replace(
          new RegExp(
            '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
            'gi'
          ),
          ' '
        );
      }
    }

    e.persist();
    this.updateActual(e);
  };

  componentWillMount() {
    this.updateActual = debounce(this.updateActual, 300);
  }

  remove = () => {
    this.props.onRemove(this.props.item);
  };

  edit = () => {
    this.setState({ isEditing: true });
  };

  finishEditing = () => {
    this.setState({ isEditing: false });
  };

  minimumPrice = () => {
    return this.props.item.minimumPrice.toFixed(2);
  };

  maximumDiscountExceeded = () => {
    return this.props.item.actual < this.minimumPrice();
  };

  render() {
    let percentage = function(n) {
      return parseFloat(n * 100).toFixed(2) + '%';
    };
    let money = function(n) {
      return '$' + parseFloat(n).toFixed(2);
    };
    let humanize = function(s) {
      let r = s.charAt(0).toUpperCase() + s.slice(1);
      return r.replace(/\-|_/, ' ');
    };
    let item = this.props.item;
    let discount =
      item.actual === item.suggestedPrice ||
      this.props.hideCommissioningData ? (
        <td />
      ) : (
        <td>
          <span className="responsive-show">Discount:</span>
          <span
            className={
              this.maximumDiscountExceeded() ? ' label label--alert' : ''
            }
          >
            {percentage(item.discount)}
          </span>
        </td>
      );

    let commission = this.props.hideCommissioningData ? (
      <td />
    ) : (
      <td>
        <span className="responsive-show">Commission:</span>
        {money(item.cmCommision)}
      </td>
    );

    let showTemplate = (
      <tbody>
        <tr>
          <td>
            <span className="responsive-show">Item: </span>
            {item.description}
          </td>
          <td>
            <span className="responsive-show">Suggested: </span>
            {money(item.suggestedPrice)}
          </td>
          <td>
            <span className="responsive-show">Actual: </span>
            <input
              className="inline-actual"
              onChange={this.onActualChange}
              type="number"
              step="any"
              min="0"
              required={true}
              defaultValue={item.actual}
              ref="actual"
              onFocus={e => {
                e.target.select();
              }}
            />
          </td>
          {commission}
          {discount}
          <td>
            <span className="responsive-show">Total Sale: </span>
            {money(item.totalSale)}
          </td>
          <td className="txt-right">
            <button className="button-naked" onClick={this.remove}>
              <i className="fa fa-times-circle txt-alert" />
            </button>
          </td>
          <td className="txt-right">
            <button className="button-naked" onClick={this.edit}>
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
        <tr>
          <td className="price-calc-details" colSpan="8">
            <span className="mrm">
              <strong>Quantity: </strong>
              {item.quantity}
            </span>
            <span className="mrm">
              <strong>Bag/Tag: </strong>
              {item.isBagAndTag ? 'Yes' : 'No'}
            </span>
            <span className="mrm">
              <strong>Ind Pay: </strong>
              {item.isIndividualPayment ? 'Yes' : 'No'}
            </span>
            <span className="mrm">
              <strong>Ind. Ship: </strong>
              {item.isIndividualShip ? 'Yes' : 'No'}
            </span>
            <span className="mrm">
              <strong>Brand Tagging: </strong>
              {item.isHeatTagging ? 'Yes' : 'No'}
            </span>
            <span
              className={
                'mrm ' + (this.props.hideCommissioningData ? 'is-hidden' : '')
              }
            >
              <strong>Shipping Type: </strong>
              {humanize(item.shippingType)}
            </span>
            <span
              className={
                'mrm ' +
                (this.props.hideCommissioningData || !this.props.fullAccess
                  ? 'is-hidden'
                  : '')
              }
            >
              <strong>Base Price: </strong>
              {money(item.price)}
            </span>
            <span
              className={
                'mrm ' +
                (this.props.hideCommissioningData || !this.props.fullAccess
                  ? 'is-hidden'
                  : '')
              }
            >
              <strong>Margin: </strong>
              {percentage(item.margin)}
            </span>
          </td>
        </tr>
      </tbody>
    );

    let editTemplate = (
      <tbody>
        <tr>
          <td colSpan="7">
            <ItemForm
              {...this.props}
              onSubmit={this.finishEditing}
              submitButtonText="Save"
              isExistingProduct
            />
          </td>
        </tr>
      </tbody>
    );

    return this.state.isEditing ? editTemplate : showTemplate;
  }
}

export default Item;
