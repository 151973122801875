import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { fetchLocationOptions } from '../stores/global-data';
import { difference, filter } from 'lodash';
import ColorSelect from './color-select';

const GlobalData = window.GlobalData;

class LocationSelect extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    location: PropTypes.string,
    prefix: PropTypes.string,
    full_error_messages: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    decoration_type: PropTypes.string,
    print_color_pms_codes: PropTypes.arrayOf(PropTypes.string),
    locked: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    isPromoProduct: false,
    id: '',
  };

  state = {
    selectedLocation: this.props.location || null,
  };

  componentWillMount() {
    fetchLocationOptions().then(() => {
      this.setState({
        locationsLoaded: true,
      });
    });
  }

  renderLocationValue = option => {
    return (
      <span>
        {option['label']} ({option['group'] || 'other'})
      </span>
    );
  };

  renderLocationOption = option => {
    return (
      <div>
        <span className="select-option-group">{option['group']}</span>
        {option['label']}
      </div>
    );
  };

  onLocationChange = selectedValues => {
    let _this = this;
    let values = selectedValues.split(',');
    this.setState(
      function(previousState) {
        return {
          selectedLocation: difference(values, [
            previousState.selectedLocation,
          ])[0],
        };
      },
      function() {
        // _this.refs.locationSelect.resetValue(); // Close the menu after one item is selected
        (_this.props.onChange || Function())(_this.state.selectedLocation);
      }
    );
  };

  errors = () => {
    if (this.props['valid?'] === false) {
      return (
        <div className="notification notification--warning stack">
          <ul>
            {this.props.full_error_messages.map(function(message, index) {
              return <li key={'errormessage-' + index}>{message}</li>;
            })}
          </ul>
        </div>
      );
    }
  };

  render() {
    const selectId = `location-select-index-${this.props.index}`;

    let options = GlobalData.locationOptions;

    let locationOptions = this.props.isPromoProduct
      ? options.promo_locations
      : options.apparel_locations;

    if (this.props.decoration_type === 'custom_tag') {
      locationOptions = locationOptions.filter(
        option => option['value'] === 'inside_back_neck'
      );
    }

    if (this.props.decoration_type === 'pick_a_pocket') {
      locationOptions = locationOptions.filter(
        option =>
          ['front_left_chest', 'front_right_chest'].indexOf(option['value']) !=
          -1
      );
    }

    let colors = this.props.print_color_pms_codes
      ? this.props.print_color_pms_codes
          .map(pms_code => {
            let color_object = filter(GlobalData.printColorOptions, {
              value: pms_code,
            })[0];
            return color_object ? color_object.label : '';
          })
          .join(', ')
      : null;

    let colorSelect;

    if (this.props.isPromoProduct) {
      if (this.props.locked) {
        colorSelect = <p>Colors: {colors}</p>;
      } else {
        colorSelect = (
          <div>
            <ColorSelect
              decorationType="screen_print"
              name={this.props.prefix + '[print_color_pms_codes]'}
              value={this.props.print_color_pms_codes}
              allowCustom={GlobalData.allowCustomPms}
              multi={true}
            />
          </div>
        );
      }
    } else {
      null;
    }

    return (
      <div className={'location form-full ' + this.props.className}>
        {this.errors()}

        <input
          type="hidden"
          name={this.props.prefix + '[id]'}
          value={this.props.id || ''}
        />
        <input
          type="hidden"
          name={this.props.prefix + '[decoration_type]'}
          value={this.props.decoration_type || ''}
        />

        <div id={selectId}>
          <Select
            name={this.props.prefix + '[location]'}
            value={this.state.selectedLocation}
            options={locationOptions}
            optionRenderer={this.renderLocationOption}
            valueRenderer={this.renderLocationValue}
            onChange={this.onLocationChange}
            placeholder="Location (type to search)"
            multi={true}
            addLabelText="Other: {label}"
            allowCreate={true}
            simpleValue={true}
          />
        </div>

        <div id={'location-promo-print-colors-index-' + this.props.index}>
          {colorSelect}
        </div>
      </div>
    );
  }
}

export default LocationSelect;
