import PropTypes from 'prop-types';
import React from 'react';
import tenant from '../tenant';
import UncontrolledSelect from './UncontrolledSelect';

class ItemForm extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onUpdate: PropTypes.func,
    products: PropTypes.array,
    submitButtonText: PropTypes.string,
    isExistingProduct: PropTypes.bool,
    hideCommissioningData: PropTypes.bool,
    fullAccess: PropTypes.bool,
  };

  static defaultProps = {
    isExistingProduct: false,
  };

  state = {
    isCustom: this.props.isExistingProduct && !this.props.item.productId,
    showTopProductUI: false,
  };

  update = () => {
    let itemFromForm = this.generateFormItem();
    this.setState({ isCustom: this.isCustomInput.checked });
    if (this.props.onUpdate) {
      this.props.onUpdate(itemFromForm);
    }
  };

  generateFormItem = () => {
    let itemFromForm = {
      quantity: this.quantityInput.value,
      isBagAndTag: this.isBagAndTagInput.checked,
      isIndividualPayment: this.isIndividualPaymentInput.checked,
      isBirdBank: this.isIndividualPaymentInput
        .checked /* It's not possible to have an Individual Payment without a Bird Bank so isBirdBank will be true whenever IndPayment is checked. */,
      isIndividualShip: this.isIndividualShipInput.checked,
      isHeatTagging: this.isHeatTaggingInput.checked,
      actual: this.props.item.actual,
    };

    if (this.state.isCustom) {
      itemFromForm.price = this.priceInput.value;
      itemFromForm.shippingType = this.shippingTypeInput.value;
      itemFromForm.description = this.descriptionInput.value;
      itemFromForm.productId = null;
    } else {
      if (
        this.productIdInput.state.value !== null &&
        this.productIdInput.state.value !== undefined
      ) {
        if (this.productIdInput.state.value.value === undefined) {
          itemFromForm.productId = this.productIdInput.state.value;
        } else {
          itemFromForm.productId = this.productIdInput.state.value.value;
        }
      }
    }

    return { ...this.props.item, ...itemFromForm };
  };

  submit = e => {
    e.preventDefault();
    let itemFromForm = this.generateFormItem();
    if (this.props.onSubmit) {
      this.props.onSubmit(itemFromForm);
    }
  };

  render() {
    let submitButtonText = this.props.submitButtonText || 'Add Item';

    let productFields = this.state.isCustom ? (
      <div>
        <div className="col-3">
          <div>
            <label htmlFor="description">Item Description</label>
            <input
              type="text"
              id="description"
              required={true}
              defaultValue={this.props.item.description}
              placeholder="Description"
              ref={input => {
                this.descriptionInput = input;
              }}
            />
          </div>
        </div>
        <div className="col-2">
          <div>
            <label htmlFor="price" className="show">
              Base Price
            </label>
            <input
              type="number"
              id="price"
              step="any"
              min="0"
              defaultValue={this.props.item.price}
              placeholder="Base Price"
              required={true}
              ref={input => {
                this.priceInput = input;
              }}
              onFocus={e => {
                e.target.select();
              }}
            />
          </div>
        </div>
        <div className="col-3">
          <label htmlFor="shipping-type" className="label-select">
            Shipping Type
            <select
              id="shipping-type"
              defaultValue={this.props.item.shippingType}
              ref={input => {
                this.shippingTypeInput = input;
              }}
              required={true}
              style={{ backgroundColor: '#F3F3F3' }}
            >
              <option value="">Shipping Type</option>
              <option value="shipping_light">Light</option>
              <option value="shipping_medium">Medium</option>
              <option value="shipping_heavy">Heavy</option>
              <option value="shipping_super_heavy">Super Heavy</option>
            </select>
          </label>
        </div>
      </div>
    ) : (
      <div className="col-10">
        <label htmlFor="product-select">Product</label>
        <UncontrolledSelect
          id="product-select"
          value={this.props.item.productId}
          placeholder="Product"
          onSelect={this.update}
          ref={input => {
            this.productIdInput = input;
          }}
          options={this.props.products.map(({ name, id }) => ({
            value: id,
            label: name,
          }))}
        />
      </div>
    );

    return (
      <form
        onChange={this.update}
        onSubmit={this.submit}
        className="form-vertical"
      >
        <h3 className="tertiary-healline mbs">Item Details</h3>

        <label>
          <input
            className="mrm mbl"
            type="checkbox"
            id="custom"
            ref={input => {
              this.isCustomInput = input;
            }}
            defaultChecked={this.state.isCustom}
          />
          Custom?
        </label>

        <div className="row">
          {productFields}

          <div className="col-2">
            <div>
              <label htmlFor="quantity">Quantity</label>
              <input
                type="number"
                id="quantity"
                step="1"
                min="1"
                required={true}
                defaultValue={this.props.item.quantity}
                ref={input => {
                  this.quantityInput = input;
                }}
                onFocus={e => {
                  e.target.select();
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <label>
              <input
                className="mrm"
                type="checkbox"
                defaultChecked={this.props.item.isIndividualPayment}
                ref={input => {
                  this.isIndividualPaymentInput = input;
                }}
                id="ind-pay"
              />
              Ind Pay Bird Bank?
            </label>
          </div>

          <div className="col-3">
            <label>
              <input
                className="mrm"
                type="checkbox"
                defaultChecked={this.props.item.isBagAndTag}
                ref={input => {
                  this.isBagAndTagInput = input;
                }}
                id="bag-tag"
              />
              Bag and Tag?
            </label>
          </div>

          <div className="col-3">
            <label>
              <input
                className="mrm"
                type="checkbox"
                defaultChecked={this.props.item.isIndividualShip}
                ref={input => {
                  this.isIndividualShipInput = input;
                }}
                id="ind-ship"
              />
              Individual ship?
            </label>
          </div>

          <div className="col-3">
            <label>
              <input
                className="mrm"
                type="checkbox"
                defaultChecked={this.props.item.isHeatTagging}
                ref={input => {
                  this.isHeatTaggingInput = input;
                }}
                id="heat-tag"
              />
              Brand tagging?
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col-3">&nbsp;</div>
          <div className="col-9">
            <button className="button button--large l-right">
              {submitButtonText}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default ItemForm;
