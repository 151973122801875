import React, { Component } from 'react';
import { debounce } from 'lodash';
import { formatMoney, formatPercent, extractValue } from '../utility';
import { localPatchJSON, extractErrorMessage } from '../fetch-local';

export default class LineItemPromoInformation extends Component {
  constructor(props) {
    super(props);
    this.setPromoInfoDebounced = debounce(this.setPromoInfo, 1000);
    this.state = { promoFieldsSaved: false };
  }

  componentWillUnmount() {
    this.setPromoInfoDebounced.cancel();
  }

  onChange = event => {
    const { props } = this;
    const target = event.target;
    const value = extractValue(target);
    const name = target.name;

    const newLineItem = {
      ...props,
      data: {
        ...props.data,
        [name]: value,
      },
    };

    props.onPricingUpdate(newLineItem);
  };

  setPromoInfo() {
    const { props } = this;

    props.onPricingUpdate({ ...props, isLoading: true });

    localPatchJSON(props.links.setPrice, {
      line_item: {
        promotion_discount: props.data.promotionDiscount,
        individual_ship_promotion: props.data.individualShipPromotion,
        promo_discount_code: props.data.promoDiscountCode,
      },
    })
      .then(data => {
        props.onPricingUpdate(data.lineItem, data.order);
        this.setState({ promoFieldsSaved: true });
      })
      .catch(error => {
        extractErrorMessage(error, 'There was an issue').then(error => {
          props.onPricingUpdate({ ...props, error });
        });
      });
  }

  componentDidUpdate(prevProps) {
    const shouldUpdatePromoFields =
      prevProps.data.promotionDiscount !== this.props.data.promotionDiscount ||
      prevProps.data.individualShipPromotion !==
        this.props.data.individualShipPromotion ||
      prevProps.data.promoDiscountCode !== this.props.data.promoDiscountCode;

    if (shouldUpdatePromoFields) {
      this.setPromoInfoDebounced();
    }
  }

  render() {
    const {
      individualShipPromotion,
      individualShipPromotionMinimum,
      promotionDiscount,
      promoDiscountCode,
    } = this.props.data;

    const { currentUser } = this.props.meta;

    const promoView = currentUser.canUpdatePricing ? (
      <div
        className="form-compact form-light form-light--bordered"
        data-test-promo-fields-saved={this.state.promoFieldsSaved}
      >
        <div className="mbm">
          <input
            placeholder="Ind. Ship Promo"
            value={individualShipPromotion || ''}
            min={individualShipPromotionMinimum}
            step={0.01}
            type="number"
            name="individualShipPromotion"
            onChange={this.onChange}
            className="individual-ship-promotion"
          />
        </div>

        <div className="mbm">
          <input
            placeholder="Promo. Discount"
            value={promotionDiscount || ''}
            step={1}
            type="number"
            name="promotionDiscount"
            onChange={this.onChange}
            className="promotional-discount"
          />
        </div>

        <div className="mbl">
          <input
            placeholder="Promo. Code"
            value={promoDiscountCode || ''}
            type="text"
            name="promoDiscountCode"
            onChange={this.onChange}
            className="promo-discount-code"
          />
        </div>
      </div>
    ) : (
      <div>
        <dl className="dl-grid">
          <dt>Individual Ship Promo:</dt>
          <dd className="txt-right">{formatMoney(individualShipPromotion)}</dd>

          <dt>Promo. Discount:</dt>
          <dd className="txt-right">{formatPercent(promotionDiscount)}</dd>

          <dt>Promo. Discount Code:</dt>
          <dd className="txt-right">{promoDiscountCode}</dd>
        </dl>
      </div>
    );

    if (currentUser.canViewPromoInfo) {
      return promoView;
    } else {
      return null;
    }
  }
}
