import React, { useCallback, useState, useEffect } from 'react';
import { Address, AddressIssue, AddressStatus } from '../address-types';
import verify from '../address-verify';
import states from '../us-states';
import { uniqueId, merge } from 'lodash';
import classNames from 'classnames';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import { isMatch } from 'lodash';
import { wrapBoldIfDiff } from '../reactUtilities';
import AddressView from './AddressView';

Modal.setAppElement('.main');

type SpacingType = 'x-small' | 'small' | 'large';

interface State {
  address: Address;
  correctedAddress: Address | null;
  willSubmitForm: boolean;
}

interface Field {
  name: string;
  value: string;
  label?: string | null;
}

interface BooleanField {
  name: string;
  value: boolean;
  label?: string | null;
}

interface Fields {
  addressLine1: Field;
  addressLine2: Field;
  city: Field;
  state: Field;
  zip: Field;
}

interface Props {
  addressLine1: Field;
  addressLine2: Field;
  city: Field;
  state: Field;
  zip: Field;

  spacing?: SpacingType;

  children?: JSX.Element;

  onChange?: (d: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
  }) => any;

  renderSubmit?: (handler: any) => JSX.Element;
  onSubmitForm?: () => any;
}

const buildDefaultFields = (): Fields => {
  return {
    addressLine1: { name: 'address_1', label: 'Address', value: '' },
    addressLine2: { name: 'address_2', value: '' },
    city: {
      name: 'city',
      label: 'City',
      value: '',
    },
    state: {
      name: 'state',
      label: 'State',
      value: '',
    },
    zip: {
      name: 'zip',
      label: 'Zip',
      value: '',
    },
  };
};

const addressLine1Id = uniqueId('address-line-1-');
const addressLine2Id = uniqueId('address-line-2-');
const cityId = uniqueId('city-');
const stateId = uniqueId('state-');
const zipId = uniqueId('zip-');

export default (props: Props) => {
  const { spacing, renderSubmit, onSubmitForm, ...providedFields } = props;
  const onChange = props.onChange || (d => {});
  const defaultFields: Fields = buildDefaultFields();
  const fields: Fields = merge(defaultFields, providedFields);

  const [state, setState] = useState<State>({
    address: {
      address: fields.addressLine1.value || '',
      address2: fields.addressLine2.value || '',
      locality: fields.city.value || '',
      region: fields.state.value || '',
      postalCode: fields.zip.value || '',
      country: 'US',
    },
    correctedAddress: null,
    willSubmitForm: false,
  });

  const { address, correctedAddress } = state;

  const [showModal, setShowModal] = useState(false);
  const [baseErrors, setBaseErrors] = useState<AddressIssue>(null);
  const [addressLine1Errors, setAddressLine1Errors] = useState<AddressIssue>(
    null
  );
  const [addressLine2Errors, setAddressLine2Errors] = useState<AddressIssue>(
    null
  );
  const [cityErrors, setCityErrors] = useState<AddressIssue>(null);
  const [stateErrors, setStateErrors] = useState<AddressIssue>(null);

  const nameForField = useCallback(
    (field: string): string => {
      const name = fields[field].name;
      return name;
    },
    [fields]
  );

  const labelForField = useCallback(
    (field: string, id: string): JSX.Element | null => {
      const text = fields[field].label;
      if (!text || text === '') return null;

      return <label htmlFor={id}>{text}</label>;
    },
    [fields]
  );

  const containerClasses = classNames({
    stack: true,
    'stack--x-small': spacing === 'x-small',
    'stack--small': spacing === 'small',
    'stack--large': spacing === 'large',
  });

  useEffect(() => {
    onChange({
      addressLine1: address.address,
      addressLine2: address.address2,
      city: address.locality,
      state: address.region,
      zip: address.postalCode,
    });
  }, [onChange, address]);

  useEffect(() => {
    const matches =
      props.addressLine1.value === state.address.address &&
      props.addressLine2.value === state.address.address2 &&
      props.city.value === state.address.locality &&
      props.state.value === state.address.region &&
      props.zip.value === state.address.postalCode;

    if (!(onSubmitForm && state.willSubmitForm && matches)) return;
    onSubmitForm();
    setState(s => ({ ...s, willSubmitForm: false }));
  }, [onSubmitForm, state, props]);

  const verifyAddress = useCallback(() => {
    return verify(address);
  }, [address]);

  const providedOnSubmitCallback = useCallback(() => {
    const results = verifyAddress();

    const clearErrors = () => {
      setAddressLine1Errors(null);
      setAddressLine2Errors(null);
      setCityErrors(null);
      setStateErrors(null);
      setBaseErrors(null);
      setState(s => ({ ...s, correctedAddress: null }));
    };

    const matchesAddress = (a: Address) => {
      const m = isMatch(address, a);
      return m;
    };

    return results
      .then(response => {
        if (response.status === AddressStatus.Verified) {
          // If the address is verified, proceed with the submit
          clearErrors();

          if (response.address) {
            setState(s => ({
              ...s,
              address: {
                ...s.address,
                ...response.address,
              },
              willSubmitForm: true,
            }));
          } else {
            setState(s => ({ ...s, willSubmitForm: true }));
          }

          return true;
        } else if (response.status === AddressStatus.Corrected) {
          // If the address was correct offer the user a choice between their
          // provided value and the  corrected value
          clearErrors();
          if (response.address) {
            if (matchesAddress(response.address)) {
              clearErrors();
              setState(s => ({ ...s, willSubmitForm: true }));
              return true;
            } else {
              setState(s => ({
                ...s,
                correctedAddress: response.address || null,
              }));
              setShowModal(true);
            }
          }
          return false;
        } else if (response.status === AddressStatus.Failed) {
          // If things have failed explain what went wrong and try to get them to fix things

          const { issues } = response;

          setState(s => ({
            ...s,
            correctedAddress: null,
          }));
          setShowModal(true);

          if (issues) {
            setAddressLine1Errors(issues.address);
            setAddressLine2Errors(issues.address2);
            setCityErrors(issues.locality);
            setStateErrors(issues.region);
            setBaseErrors(issues.base);
          } else {
            setBaseErrors([
              'Unknown address. Please verify that you have entered the correct information before proceeding',
            ]);
          }

          return false;
        }
      })
      .catch(e => {
        setShowModal(true);
        setState(s => ({
          ...s,
          correctedAddress: null,
        }));
        setBaseErrors(['There was an issue verifying your address']);
        return false;
      });
  }, [address, verifyAddress]);

  const modal = () => {
    const providedAddress = <AddressView {...address} />;

    const suggestedAddress = correctedAddress && (
      <AddressView
        address={wrapBoldIfDiff(address.address, correctedAddress.address)}
        address2={wrapBoldIfDiff(address.address2, correctedAddress.address2)}
        locality={wrapBoldIfDiff(address.locality, correctedAddress.locality)}
        region={wrapBoldIfDiff(address.region, correctedAddress.region)}
        postalCode={wrapBoldIfDiff(
          address.postalCode,
          correctedAddress.postalCode
        )}
      />
    );

    return (
      <Modal
        isOpen={showModal}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Shipping Address Verification Modal"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
          <h4 className="react-modal-header__title">
            Please Verify Your Shipping Address
          </h4>
          <button
            className="button-naked"
            type="button"
            onClick={() => setShowModal(false)}
          >
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        {correctedAddress && (
          <div className="stack stack--small">
            <div className="react-modal-body react-modal-content">
              <div className="notification">
                <h2 className="small-headline">You entered:</h2>
                {providedAddress}
              </div>

              <div className="notification notification--notice mbn">
                <h2 className="small-headline">We suggest:</h2>
                {suggestedAddress}
              </div>
            </div>
          </div>
        )}

        {!correctedAddress && (
          <div className="react-modal-body react-modal-content">
            <div>
              {[
                addressLine1Errors,
                addressLine2Errors,
                cityErrors,
                stateErrors,
                baseErrors,
              ].map(e => {
                if (!e || e.length < 1) return false;

                return (
                  <div className="notification notification--alert txt-em">
                    <h2 className="small-headline">
                      The following errors were detected in the address your
                      provided:
                    </h2>
                    <ul className="mbn">
                      {e.map(l => (
                        <li key={uniqueId()}>{l}</li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>

            <div className="notification">
              <h2 className="small-headline">You entered:</h2>
              {providedAddress}
            </div>
          </div>
        )}

        <div className="react-modal-footer">
          <div className="react-modal-footer__cta flex-rows--wrap flex-gap-x-sm">
            <div
              className={classNames({
                'flex-rows': true,
                'flex-gap-x-sm': true,
                'flex-order-2': !correctedAddress,
              })}
            >
              {correctedAddress && (
                <button
                  onClick={() => {
                    setState(s => ({
                      ...s,
                      address: { ...s.address, ...(correctedAddress || null) },
                      willSubmitForm: true,
                    }));
                  }}
                  className="button"
                  type="button"
                >
                  Use Suggested Address
                </button>
              )}

              <button
                onClick={() => setState(s => ({ ...s, willSubmitForm: true }))}
                className={classNames({
                  button: !!correctedAddress,
                  'button--secondary': !!correctedAddress,
                  'button-naked': !correctedAddress,
                })}
                type="button"
              >
                Keep Address As Is
              </button>
            </div>

            <button
              type="button"
              className={classNames({
                mla: !!correctedAddress,
                button: !correctedAddress,
                'button-naked': correctedAddress,
                'flex-order-1': !correctedAddress,
              })}
              onClick={() => setShowModal(false)}
            >
              {correctedAddress ? 'Cancel' : 'Cancel and Fix Address'}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={containerClasses}>
      <div className="stack stack--small">
        {baseErrors && (
          <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
            {baseErrors}
          </div>
        )}

        {props.children}

        <div className="stack stack--x-small">
          {labelForField('addressLine1', addressLine1Id)}
          {addressLine1Errors && (
            <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
              {addressLine1Errors}
            </div>
          )}
          <input
            type="text"
            placeholder="Street address"
            id={addressLine1Id}
            value={address.address}
            name={nameForField('addressLine1')}
            onChange={e =>
              setState(s => ({
                ...s,
                address: {
                  ...s.address,
                  address: e.target.value,
                },
              }))
            }
            className={classNames({
              'field-error': !!addressLine1Errors,
            })}
          />
        </div>

        <div className="stack stack--x-small">
          {labelForField('addressLine2', addressLine2Id)}
          {addressLine2Errors && (
            <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
              {addressLine2Errors}
            </div>
          )}
          <input
            type="text"
            placeholder="Apt, suite, unit, building, floor, etc."
            id={addressLine2Id}
            value={address.address2}
            name={nameForField('addressLine2')}
            onChange={e =>
              setState(s => ({
                ...s,
                address: {
                  ...s.address,
                  address2: e.target.value,
                },
              }))
            }
            className={classNames({
              'field-error': !!addressLine2Errors,
            })}
          />
        </div>
      </div>

      <div className="stack stack--x-small">
        {labelForField('city', cityId)}
        {cityErrors && (
          <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
            {cityErrors}
          </div>
        )}
        <input
          id={cityId}
          type="text"
          value={address.locality}
          placeholder="City"
          name={nameForField('city')}
          onChange={e =>
            setState(s => ({
              ...s,
              address: {
                ...s.address,
                locality: e.target.value,
              },
            }))
          }
          className={classNames({
            invalid: !!cityErrors,
          })}
        />
      </div>

      <div className="label-select stack stack--x-small">
        {labelForField('state', stateId)}
        {stateErrors && (
          <div className="mvs notification notification--alert notification--compact notification--small bb-notification bb-notification--alert bb-notification--small">
            {stateErrors}
          </div>
        )}
        <select
          id={stateId}
          value={address.region}
          name={nameForField('state')}
          onChange={e =>
            setState(s => ({
              ...s,
              address: {
                ...s.address,
                region: e.target.value,
              },
            }))
          }
          className={classNames({
            invalid: !!stateErrors,
          })}
        >
          <option value="">Choose a State</option>
          {states.map(({ abbreviation, name }) => (
            <option key={abbreviation} value={abbreviation}>
              {name}
            </option>
          ))}
        </select>

        <div className="stack stack--x-small">
          {labelForField('zip', zipId)}
          <input
            id={zipId}
            type="text"
            value={address.postalCode}
            name={nameForField('zip')}
            placeholder={fields['zip'].label || 'Zip'}
            onChange={e =>
              setState(s => ({
                ...s,
                address: {
                  ...s.address,
                  postalCode: e.target.value,
                },
              }))
            }
          />
        </div>

        {modal()}

        {renderSubmit && renderSubmit(providedOnSubmitCallback)}
      </div>
    </div>
  );
};
