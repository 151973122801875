import React, { Component } from 'react';
import LineItem from './LineItem';
import { localGet, localPatchJSON } from '../fetch-local';
import Loader from './loader';
import OrderPriceBreakdown from './OrderPriceBreakdown';
import CommissionableDetails from './CommissionableDetails';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AddLineItemsToBirdBank from './AddLineItemsToBirdBank';
import { every, filter } from 'lodash';
import get from 'lodash.get';
import MinimumQuantityAlert from './MinimumQuantityAlert';

export default class LineItemManager extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onDragEnd = result => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newLineItems = Array.from(this.state.lineItems);
    const movedLineItem = newLineItems.splice(source.index, 1)[0];
    newLineItems.splice(destination.index, 0, movedLineItem);

    this.setState({ lineItems: newLineItems });

    const moveUrl = this.props.moveUrl;

    const positions = newLineItems
      .map(({ id }, index) => ({
        [id]: { position: index + 1 },
      }))
      .reduce((accum, current) => ({ ...accum, ...current }), {});

    localPatchJSON(moveUrl, { line_items: positions });
  };

  componentDidMount() {
    const url = this.props.dataUrl;
    localGet(url).then(data => this.setState(data));
  }

  onPricingUpdate = (newLineItem, newOrder = {}) => {
    this.setState(prevState => ({
      lineItems: prevState.lineItems.map(lineItem => {
        if (lineItem.id === newLineItem.id) {
          return newLineItem;
        }
        return lineItem;
      }),
      order: { ...prevState.order, ...newOrder },
    }));
  };

  toggleReview = (value, lineItemId) => {
    this.setState(prevState => ({
      lineItems: prevState.lineItems.map(lineItem => {
        if (lineItem.id === lineItemId) {
          lineItem.data['reviewOpen'] = value;
          return lineItem;
        }
        return lineItem;
      }),
    }));
  };

  lineItems = () => {
    const { onPricingUpdate, toggleReview } = this;
    const { lineItems } = this.state;

    if (!lineItems) return <Loader />;

    return (
      <Droppable droppableId={'1'}>
        {provided => (
          <div
            className="reorder-list-container"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {lineItems.map((lineItem, index) => {
              const lineItemProps = {
                ...lineItem,
                onPricingUpdate,
                toggleReview,
              };

              return (
                <LineItem
                  key={lineItem.id}
                  draggable={lineItems.length > 1}
                  {...lineItemProps}
                  index={index}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  commissionableDetails = () => {
    const { order } = this.state;
    if (!order || !this.state.meta.currentUser) return <Loader />;

    const lineItems = get(this.state, ['lineItems'], []);

    const allLineItemsHavePrices = every(lineItems, lineItem => {
      return get(lineItem, ['data', 'unitPrice']) != null;
    });

    const displayAddUnitPriceMessage = (
      <small>
        <em className="small txt-muted2">
          Add unit prices for all items in order to see commission details.
        </em>
      </small>
    );

    const displayPromoProductDisclaimer = (
      <small>
        <em className="small txt-muted2">
          Commission details are not yet available for promo product orders.
        </em>
      </small>
    );

    const canViewCommissionDetails = this.state.meta.currentUser
      .canViewCommissionDetails;

    if (canViewCommissionDetails && this.state.order.promoProduct) {
      return displayPromoProductDisclaimer;
    } else if (canViewCommissionDetails && allLineItemsHavePrices) {
      return <CommissionableDetails {...this.state} />;
    } else if (canViewCommissionDetails) {
      return displayAddUnitPriceMessage;
    }
  };

  totals = () => {
    const { order } = this.state;
    if (!order) return <Loader />;
    return <OrderPriceBreakdown {...this.state} />;
  };

  assignableBirdBankItems = () =>
    filter(
      this.state.lineItems,
      item => item.data.birdBank && !item.data.birdBankItemId
    );

  render() {
    const addLineItemsToBirdBank = this.props.addLineItemsToBirdBankEnabled ? (
      <AddLineItemsToBirdBank
        lineItems={this.assignableBirdBankItems()}
        order={this.state.order}
        addToBirdBankUrlTemplate={this.props.addToBirdBankUrlTemplate}
        birdBankSearchUrlTemplate={this.props.birdBankSearchUrlTemplate}
        birdBankLineItemMaxQuantity={this.props.birdBankLineItemMaxQuantity}
      />
    ) : null;

    return (
      <div className="line-item-manager">
        <MinimumQuantityAlert order={this.state} />
        <DragDropContext onDragEnd={this.onDragEnd}>
          {this.lineItems()}
        </DragDropContext>
        <div className="content-grid--2">
          <div>{this.commissionableDetails()}</div>
          <div>{this.totals()}</div>
        </div>

        {addLineItemsToBirdBank}
      </div>
    );
  }
}
