$('[data-confirm="opportunity-event-date"]').on('click', function(e) {
  let eventDateAttr = $('[data-select="opportunity-event-date"]')[0];
  let rawDate = eventDateAttr.getElementsByTagName('input')[0].value;
  let selectedDate = new Date(rawDate);
  let todaysDate = new Date(Date.now());
  let idealEventDate = new Date(new Date().setDate(todaysDate.getDate() + 56)); // 8 weeks

  if (selectedDate < idealEventDate) {
    e.preventDefault();

    const oneDay = 1000 * 60 * 60 * 24;

    let dateDifference = Math.round((selectedDate - todaysDate) / oneDay);
    let timeUntilEvent;
    let timeMsg;

    if (dateDifference <= 13) {
      timeMsg = `${dateDifference} days`;
    } else {
      timeUntilEvent = Math.round(dateDifference / 7);
      timeMsg = `${timeUntilEvent} weeks`;
    }

    // Launch modal
    swal(
      {
        title: 'Are you sure?',
        text: `<p class='mbm'>Target Close Date: ${rawDate} is only ${timeMsg} from now.</p>`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Go back',
        confirmButtonColor: '#FE4438',
        confirmButtonText: 'Confirm',
        html: true,
        customClass: 'rush-alert',

        closeOnConfirm: false,
      },
      function() {
        $('form[id^="edit-opportunity"]').trigger('submit');
      }
    );
  }
});
