import React, { useState } from 'react';
import { Async, AsyncCreatable } from 'react-select';
import classNames from 'classnames';
import { DecorationType, DecorationColor } from '../base-types';
import { isEmpty, escapeRegExp } from 'lodash';
import { getColors } from '../stores/Color';
import { labelForDecorationTypeColor } from '../labels';
import ColorItem from './ColorItem';
import { createCustomColor } from '../stores/Color';

type Value = DecorationColor | DecorationColor[];

interface Props {
  decorationType: DecorationType;
  allowCustom?: boolean;
  className?: string;
  isPromoProduct?: boolean;
  value: Value;
  onChange: (v: Value) => any;
  placeholder?: string;
  readonly?: boolean;
}

interface Option {
  label: string;
  value: string;
}

interface NewOption extends Option {
  labelKey: string;
  valueKey: string;
}

interface ReactSelectOption extends Option {
  hex?: string;
  pms: string;

  clearableValue?: boolean;
}

const toOption = (color: DecorationColor): ReactSelectOption => ({
  value: color.id.toString(),
  label: color.name,
  hex: color.hex,
  pms: color.pms,
});

function newOptionCreator(option: Option): NewOption {
  const inputValue = option.label.toUpperCase();

  return {
    label: `Add PMS(${inputValue})`,
    value: inputValue,
    labelKey: inputValue,
    valueKey: inputValue,
  };
}

const promptTextCreator = (label: string): string => label;

function valueRenderer(color: ReactSelectOption) {
  const swatchClasses = classNames({
    media__obj: true,
    'color-preview': true,
    'color-preview-cmyk': color.label === 'CMYK',
  });

  const swatchStyles = {
    backgroundColor: `#${color.hex}`,
  };

  return (
    <div className="media media--center">
      <div className={swatchClasses} style={swatchStyles} />
      <div className="media__body">{color.label}</div>
    </div>
  );
}

function filterOption(
  option: ReactSelectOption,
  filterString: string
): boolean {
  let a = [option.label, `PMS ${option.pms}`, `#${option.hex}`];

  return a.reduce(
    (memo, attribute) =>
      memo ||
      (!!attribute &&
        attribute.toLowerCase().indexOf(filterString.toLowerCase()) !== -1),
    false
  );
}

function isValidNewOption(option) {
  return !isEmpty(option.label);
}

export default function ColorSelect(props: Props) {
  const { readonly } = props;

  const [isLoading, setIsLoading] = useState(false);

  const toCustomColor = (option: ReactSelectOption): DecorationColor => ({
    id: Number.parseInt(option.value),
    name: option.label,
    pms: option.pms,
    hex: option.hex,
    decorationType: props.decorationType,
  });

  function filterOptions(
    options: ReactSelectOption[],
    filter: string
  ): ReactSelectOption[] {
    return options.filter(option => {
      const text = `${option.label} ${option.pms}`;
      const query = escapeRegExp(filter);
      const matchesText = text.match(new RegExp(query, 'gi'));
      let notSelected: boolean;
      if (props.value instanceof Array) {
        notSelected =
          props.value.filter(v => v.id.toString() === option.value).length ===
          0;
      } else {
        notSelected = true;
      }
      return notSelected && matchesText;
    });
  }

  function optionRenderer(option: ReactSelectOption) {
    const pms_label = (
      <span className="hide-on-select">(PMS {option.pms})</span>
    );

    const swatchClasses = classNames({
      'color-preview': true,
      'color-preview-cmyk': option.label === 'CMYK',
    });

    const swatchStyles = {
      backgroundColor: `#${option.hex}`,
    };

    return (
      <span>
        <div className={swatchClasses} style={swatchStyles} />
        {option.label} {option.pms ? pms_label : null}
      </span>
    );
  }

  let multi = false;
  let value: string;

  const decorationColorToValue = ({ id }: DecorationColor): string =>
    id.toString();

  if (props.value instanceof Array) {
    value = props.value.map(decorationColorToValue).join(',');
    multi = true;
  } else {
    value = decorationColorToValue(props.value);
  }

  const loadOptions = async (): Promise<{ options: ReactSelectOption[] }> => {
    const colors = await getColors();
    const standardOptions = colors
      .filter(c => {
        if (props.decorationType === DecorationType.Promo)
          return c.decorationType === DecorationType.ScreenPrint;
        return c.decorationType === props.decorationType;
      })
      .map(toOption);

    let customOptions: ReactSelectOption[] = [];

    if (props.value instanceof Array) {
      customOptions = props.value.map(toOption);
    } else {
      customOptions = [toOption(props.value)];
    }

    const options = [...standardOptions, ...customOptions];

    return { options };
  };

  const onChange = (
    value: ReactSelectOption[] | ReactSelectOption | undefined
  ) => {
    if (value instanceof Array) {
      props.onChange(value.map(toCustomColor));
    } else if (!!value) {
      props.onChange(toCustomColor(value));
    }
  };

  async function onNewOptionClick(inputValue) {
    setIsLoading(true);

    try {
      const c: DecorationColor = await createCustomColor(
        props.decorationType,
        inputValue.value
      );

      if (props.value instanceof Array) {
        props.onChange([...props.value, c]);
      } else if (!!props.value) {
        props.onChange(c);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const searchPromptText = 'Start typing to search';
  const placeholder =
    props.placeholder ||
    `Select ${labelForDecorationTypeColor(props.decorationType)}`;

  const selectProps = {
    loadOptions,
    optionRenderer,
    multi,
    filterOption,
    searchPromptText,
    onChange,
    placeholder,
    valueRenderer,
    value,
    isLoading,
    key: JSON.stringify(props.value),
    // https://github.com/JedWatson/react-select/issues/761#issuecomment-400673462
    // Basicaly the above "key" prop will change anytime the value changes
    // forcing a remount, this is necessary to resync state after async
    // creation of new colors which V1 of ReactSelect really gets confused
    // about
  };

  const createProps = {
    ...selectProps,
    newOptionCreator,
    promptTextCreator,
    isValidNewOption,
    filterOptions, // Note that this will override the filterOption setting set above: https://github.com/JedWatson/react-select/tree/v1.x#advanced-filters
    onNewOptionClick,
  };

  const SelectComponent = props.allowCustom ? (
    <AsyncCreatable {...createProps} />
  ) : (
    <Async {...selectProps} />
  );

  let readOnlyColors: JSX.Element[] = [];
  if (props.value instanceof Array) {
    readOnlyColors = props.value.map(c => (
      <span key={c.id} className="mrm">
        <ColorItem color={c} />
      </span>
    ));
  } else {
    readOnlyColors = [
      <div key={0}>
        <ColorItem key={props.value.id} color={props.value} />
      </div>,
    ];
  }

  return (
    <>
      {!readonly && SelectComponent}
      {readonly && <div>{readOnlyColors}</div>}
    </>
  );
}
