import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

const GlobalData = window.GlobalData;

class LocationDecorationTypeSelector extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    nonce: PropTypes.string,
    locationsModel: PropTypes.object,
    prefix: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      changeDecorationType: false,
    };
  }

  updateLocationDecorationType = (decoration_type, event) => {
    event.preventDefault();
    this.props.locationsModel.updateLocation(this.props.nonce, {
      decoration_type,
      thread_count: null,
    });

    this.setState({
      changeDecorationType: false,
    });
  };

  resetDecorationType = event => {
    event.preventDefault();

    this.setState({
      changeDecorationType: true,
    });
  };

  render() {
    let { decoration_type, isPromoProduct } = this.props;

    let hasSignupLineItems = this.props['has_signup_line_items?'];

    if (decoration_type && !this.state.changeDecorationType) {
      let typeLabel = find(GlobalData.decorationTypeOptions, {
        value: decoration_type,
      })['label'];

      return (
        <div className="mbm">
          <strong>{typeLabel}</strong>
          <span>
            {isPromoProduct ? null : (
              <span>
                &nbsp;(
                <a href="#" onClick={this.resetDecorationType}>
                  change
                </a>
                )
              </span>
            )}
            <input
              type="hidden"
              name={this.props.prefix + '[decoration_type]'}
              value={decoration_type}
            />
          </span>
        </div>
      );
    } else {
      let customTagSelect = GlobalData.isBusiness ? (
        <button
          type="button"
          href="#"
          className="mbl button button--large button--full button--secondary"
          key="add-custom-tag-location"
          id="add-custom-tag-location"
          onClick={this.updateLocationDecorationType.bind(this, 'custom_tag')}
          title="Custom Tag"
        >
          <i className="fa fa-plus" /> Custom Tag
        </button>
      ) : null;

      return (
        <div>
          <h4 className="mtn tertiary-headline txt-center">
            Choose a decoration type
          </h4>

          <button
            type="button"
            href="#"
            className="mbm button button--large button--full button--secondary"
            key="add-screen-print-location"
            id="add-screen-print-location"
            onClick={this.updateLocationDecorationType.bind(
              this,
              'screen_print'
            )}
            title="Screen Print"
            disabled={hasSignupLineItems && decoration_type == 'heat_press'}
          >
            <i className="fa fa-plus" /> Screen Print
          </button>
          <button
            type="button"
            href="#"
            className="mbm button button--large button--full button--secondary"
            key="add-embroidery-location"
            id="add-embroidery-location"
            onClick={this.updateLocationDecorationType.bind(this, 'embroidery')}
            title="Embroidery"
            disabled={hasSignupLineItems && decoration_type == 'heat_press'}
          >
            <i className="fa fa-plus" /> Embroidery
          </button>
          <button
            type="button"
            href="#"
            className="mbm button button--large button--full button--secondary"
            key="add-heat-press-location"
            id="add-heat-press-location"
            onClick={this.updateLocationDecorationType.bind(this, 'heat_press')}
            title="Heat Press"
            disabled={hasSignupLineItems && decoration_type != 'heat_press'}
          >
            <i className="fa fa-plus" /> Heat Press
          </button>
          <button
            type="button"
            href="#"
            className="mbm button button--large button--full button--secondary"
            key="add-tackle_twill-location"
            id="add-tackle_twill-location"
            onClick={this.updateLocationDecorationType.bind(
              this,
              'tackle_twill'
            )}
            title="Tackle Twill"
            disabled={hasSignupLineItems && decoration_type == 'heat_press'}
          >
            <i className="fa fa-plus" /> Tackle Twill
          </button>
          <button
            type="button"
            href="#"
            className="mbm button button--large button--full button--secondary"
            key="add-flocking-location"
            id="add-flocking-location"
            onClick={this.updateLocationDecorationType.bind(this, 'flocking')}
            title="Flocking"
            disabled={hasSignupLineItems && decoration_type == 'heat_press'}
          >
            <i className="fa fa-plus" /> Custom Vinyl / Flock
          </button>

          {customTagSelect}
        </div>
      );
    }
  }
}

export default LocationDecorationTypeSelector;
