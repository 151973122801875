import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function AddToBirdBankButton(props) {
  const domNode = document.getElementById('line-item-bird-bank-button-root');

  if (!domNode)
    return null;

  const onClick = e => {
    e.preventDefault();
    props.onClick();
  };

  return ReactDOM.createPortal(
    <a href="#" onClick={onClick} className="button">
      Add to Bird Bank
    </a>,
    domNode
  );
}

AddToBirdBankButton.propTypes = {
  onClick: PropTypes.func,
};

export default AddToBirdBankButton;
