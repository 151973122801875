import React from 'react';
import { formatMoney } from '../utility';
import Loader from './loader';
import ExpandDetails from './ExpandDetails';

const CommissionableDetails = props => {
  const { order } = props;

  if (!order) return <Loader />;

  const totalCommission = order.totalCommission ? (
    <p className="flex-rows flex-rows--space-b flex-gap">
      <strong>Total Commission</strong>
      <strong className="total-value--res">
        {formatMoney(order.totalCommission)}
      </strong>
    </p>
  ) : (
    ''
  );

  const rushFeeDisclaimer = order.includedRushFees > 0 && (
    <div>
      <hr class="mvl"></hr>
      <p className="flex-rows flex-rows--space-b flex-gap txt-warning">
        <span>Rush Fees Included in Pricing</span>
        <span className="total-value--res">
          {formatMoney(order.includedRushFees)}
        </span>
      </p>
    </div>
  );

  const nonCommissionablesList = () => {
    const data = [
      { name: 'Ind Pay Bird Bank', value: order.individualPayment },
      { name: 'Bag & Tag', value: order.bagTag },
      { name: 'Individual Ship', value: order.individualShip },
      { name: 'Royalties', value: order.royalties },
      { name: 'Rush Fee', value: order.rushFeeAndShippingFees > 0 },
      { name: 'Brand Tagging', value: order.brandTagging },
    ];

    return (
      <ul className="list-plain mbn">
        {data.map((item, i) => (
          <li className="list-anchored" key={i}>
            {item.value ? (
              <span className="fa fa-check-circle txt-success"></span>
            ) : (
              <span></span>
            )}
            <span className="txt-small">{item.name}</span>
          </li>
        ))}
      </ul>
    );
  };

  const displaySalesTax = order.subjectToTax && (
    <p className="flex-rows flex-rows--space-b flex-gap">
      <span>Sales Tax</span>
      <span className="total-value--res">{formatMoney(order.salesTax)}</span>
    </p>
  );

  const displayTotalPreTax = order.subjectToTax && (
    <p className="flex-rows flex-rows--space-b flex-gap">
      <span>Pre-Tax Total</span>
      <span className="total-value--res">
        {formatMoney(order.totalSalePretax)}
      </span>
    </p>
  );

  const displayCommissionPaidDisclaimer = order.commissionPaid && (
    <div className="notification notification--compact notification--notice stack notification--small">
      <em>
        Commission has been paid on this order. If the order has changed, this
        information may not reflect the paid commission amount.
      </em>
    </div>
  );

  const nonCommissionablePortion = formatMoney(
    (order.totalSalePretax - order.commissionableSale).toFixed(2)
  );

  const displayCommissionableSaleDetails = (
    <>
      <hr />
      <div className="stack stack--x-small">
        <p className="flex-rows flex-rows--space-b flex-gap">
          <span>Commissionable Portion</span>
          <span className="total-value--res">
            {formatMoney(order.commissionableSale)}
          </span>
        </p>
        <p className="flex-rows flex-rows--space-b">
          <span>Non-Commissionable Portion</span>
          <span className="total-value--res">{nonCommissionablePortion}</span>
        </p>
        {nonCommissionablesList()}
        {displayTotalPreTax}
        {displaySalesTax}
        <p className="flex-rows flex-rows--space-b flex-gap">
          <strong>Total</strong>
          <strong className="total-value--res">
            {formatMoney(order.totalSale)}
          </strong>
        </p>
      </div>
    </>
  );

  return (
    <div className="callout-light-gray callout-rounded pal stack">
      {displayCommissionPaidDisclaimer}
      <div className="stack">
        <div className="flex-rows flex-rows--space-b flex-rows--center-v">
          <h3 className="mvn">Order Commission Details</h3>
        </div>
        <div className="stack stack--x-small">{totalCommission}</div>
      </div>
      {rushFeeDisclaimer}
      <ExpandDetails detailsExpanded={displayCommissionableSaleDetails} />
    </div>
  );
};

export default CommissionableDetails;
