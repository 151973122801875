import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { localGet } from '../fetch-local';
import Notification from './notification';
import { setField, setSelectField, simulateMouseClick } from '../terribleHacks';

type optionalId = number | null;

interface Props {
  businessName: string;
  customerProfileName: string;
  selectedBusinessId: optionalId;
  selectedCustomerProfileId: optionalId;
  businesses: {
    id: number;
    name: string;
  }[];
  links: {
    customerProfiles: string;
  };
  orderPersisted: boolean;
  canEditBusiness: boolean;
}

interface CustomerProfile {
  id: number;
  name: string;
  address1: string | null;
  address2: string | null;
  address3: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
}

const hackyFormFieldSetting = (customerProfile: CustomerProfile | null) => {
  if (customerProfile) {
    setField('customer_address_three', customerProfile.address3);
    setField('customer_address_one', customerProfile.address1);
    window.setTimeout(() => {
      const outsideArea = document.getElementById('admin-section');
      if (outsideArea) {
        simulateMouseClick(outsideArea);
      }
    }, 500);

    setField('customer_address_two', customerProfile.address2);
    setField('customer_city', customerProfile.city);
    setSelectField('customer_state', customerProfile.state);
    setField('customer_zip', customerProfile.zip);
  } else {
    setField('customer_address_three', '');
    setField('customer_address_one', '');
    setField('customer_address_two', '');
    setField('customer_city', '');
    setSelectField('customer_state', '');
    setField('customer_zip', '');
  }
};

const BusinessAndCustomerProfileSelect = (props: Props) => {
  const [selectedBusinessId, setSelectedBusinessId] = useState<optionalId>(
    props.selectedBusinessId
  );
  const [selectedCustomerProfileId, setSelectedCustomerProfileId] = useState<
    optionalId
  >(props.selectedCustomerProfileId);

  const [customerProfiles, setCustomerProfiles] = useState<
    CustomerProfile[] | null
  >(null);

  useEffect(() => {
    if (!selectedBusinessId) return;
    let url = props.links.customerProfiles.replace(
      '__business_id__',
      selectedBusinessId.toString()
    );

    if (props.selectedCustomerProfileId) {
      url = url + `?selected=${props.selectedCustomerProfileId}`;
    }

    localGet(url).then(customerProfiles =>
      setCustomerProfiles(customerProfiles)
    );
  }, [
    selectedBusinessId,
    props.links.customerProfiles,
    props.selectedCustomerProfileId,
  ]);

  const businessSelectProps = {
    name: props.businessName,
    id: 'business-select',
    options: props.businesses.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    onChange: id => setSelectedBusinessId(id),
    value: selectedBusinessId,
    placeholder: 'Select a Business',
    simpleValue: true,
    disabled: props.canEditBusiness ? false : props.orderPersisted,
  };

  const supportEditBusinessWarning = props.canEditBusiness &&
    props.orderPersisted && (
      <Notification type="support" small>
        When updating a business, we must manually update the payment terms to
        reflect the new business's payment terms.
      </Notification>
    );

  const businessView = (
    <div className="stack stack--x-small">
      <label htmlFor="business-select">Business</label>
      {supportEditBusinessWarning}
      <Select {...businessSelectProps} />
    </div>
  );

  const customerProfileSelectProps = customerProfiles && {
    name: props.customerProfileName,
    id: 'customer-profile-select',
    options: customerProfiles.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
    onChange: id => {
      setSelectedCustomerProfileId(id);
      const cp = customerProfiles.find(c => c.id === id);
      !props.orderPersisted && cp && hackyFormFieldSetting(cp);
    },
    value: selectedCustomerProfileId,
    placeholder: 'Select a Customer',
    simpleValue: true,
  };

  const editingCustomerProfileWarning = props.orderPersisted && (
    <Notification type="notice" small>
      Editing or selecting a new customer profile after an order has already
      been created will only update the attached customer profile and not the
      order's shipping name or address.
    </Notification>
  );

  const customerProfileView = customerProfiles && (
    <div id="customer-select" className="stack stack--x-small">
      <label htmlFor="business">Customer Profile</label>
      <div className="mbn">{editingCustomerProfileWarning}</div>
      {<Select {...customerProfileSelectProps} />}
    </div>
  );

  const businessUrl =
    selectedBusinessId &&
    `/businesses/${selectedBusinessId}/customer_profiles/new`;

  const customerUrl =
    selectedCustomerProfileId &&
    `/businesses/${selectedBusinessId}/customer_profiles/${selectedCustomerProfileId}/edit`;

  const editCustomerView = customerUrl && (
    <a
      href={customerUrl}
      className="button button--secondary"
      target="_blank"
      rel="noreferrer"
    >
      Edit
    </a>
  );
  const addNewCustomerView = businessUrl && (
    <div>
      <a
        href={businessUrl}
        className="button button--small button--secondary"
        target="_blank"
        rel="noreferrer"
      >
        Create a new customer
      </a>
    </div>
  );

  return (
    <div className="stack">
      {businessView}
      {customerProfileView && (
        <div className="stack stack--small">
          <div className="media media--end">
            <div className="media__body">{customerProfileView}</div>
            <div className="media__obj--rev">{editCustomerView}</div>
          </div>
        </div>
      )}
      {addNewCustomerView}
    </div>
  );
};

export default BusinessAndCustomerProfileSelect;
