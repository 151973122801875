import React from 'react';
import ColorList from './ColorList';

const LineItemColorChanges = props => {
  const { data } = props;
  const locations = data.locations || [];

  const colors = locations.map(location => (
    <ColorList key={location.id} {...location} />
  ));

  return colors.length > 0 && (
    <div>
      {colors}
    </div>
  );
};

export default LineItemColorChanges;
