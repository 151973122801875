import { localGet, localPostJSON } from '../fetch-local';
import { Id, DecorationType, DecorationColor } from '../base-types';

interface Response {
  id: Id;
  decoration_type: string;
  hex?: string;
  label: string;
  pms: string;
}

function stringToDecorationType(s: string): DecorationType | undefined {
  switch (s) {
    case 'screen_print':
      return DecorationType.ScreenPrint;
    case 'embroidery':
      return DecorationType.Embroidery;
    case 'heat_press':
      return DecorationType.HeatPress;
    case 'tackle_twill':
      return DecorationType.TackleTwill;
    case 'pick_a_pocket':
      return DecorationType.PickAPocket;
    case 'flocking':
      return DecorationType.Flocking;
    case 'promo':
      return DecorationType.Promo;
    case 'custom_tag':
      return DecorationType.CustomTag;
  }
}

export function getColors(orderId?: Id): Promise<DecorationColor[]> {
  const predicate = orderId ? `?order_id=${orderId}` : '';
  const url = `/api/v1/print_colors${predicate}`;

  const fetchPromise: Promise<Response[]> = localGet(url);

  return fetchPromise.then(data => {
    const colors: DecorationColor[] = data.reduce(
      (accum, { id, decoration_type, pms, hex, label }) => {
        const decorationType = stringToDecorationType(decoration_type);
        if (decorationType === undefined) return accum;

        return [
          ...accum,
          {
            id,
            name: label,
            pms,
            hex,
            decorationType,
          },
        ];
      },
      []
    );
    return colors;
  });
}

export function createCustomColor(
  decorationType: DecorationType,
  colorName: string
): Promise<DecorationColor> {
  const payload = {
    print_color: {
      pms: colorName,
      decoration_type: decorationType,
    },
  };

  return localPostJSON(`/api/v1/custom_print_colors`, payload);
}
