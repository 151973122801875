// Purpose of this function is to insert Company address for pick-up items
(function() {
  const { address } = window.GlobalData;
  $('.shipping-methods').click(function(e) {
    if (
      e.target.id ===
      'order_art_jobs_attributes_0_shipping_method_pick_up_order'
    ) {
      $('#order_customer_address_one')
        .val(address.addressOne)
        .change();
      $('#order_customer_address_two')
        .val(address.addressTwo)
        .change();
      $('#order_customer_city')
        .val(address.city)
        .change();
      $('#order_customer_state')
        .val(address.state)
        .change();
      $('#order_customer_zip')
        .val(address.zip)
        .change();

      window.setTimeout(function() {
        $('#order_customer_address_one').addClass('readonly');
        $('#order_customer_address_two').addClass('readonly');
        $('#order_customer_city').addClass('readonly');
        $('#order_customer_state').addClass('readonly');
        $('#order_customer_zip').addClass('readonly');
        $('#order_customer_address_one').prop('readonly', true);
        $('#order_customer_address_two').prop('readonly', true);
        $('#order_customer_city').prop('readonly', true);
        $('#order_customer_state').prop('readonly', true);
        $('#order_customer_zip').prop('readonly', true);
      }, 600);
    } else {
      $('#order_customer_address_one')
        .val($('#order_customer_address_one').data('originaladdressone'))
        .change();
      $('#order_customer_address_two')
        .val($('#order_customer_address_two').data('originaladdresstwo'))
        .change();
      $('#order_customer_city')
        .val($('#order_customer_city').data('originalcity'))
        .change();
      $('#order_customer_state')
        .val($('#order_customer_city').data('originalstate'))
        .change();
      $('#order_customer_zip')
        .val($('#order_customer_city').data('originalzip'))
        .change();

      $('#order_customer_address_one').removeClass('readonly');
      $('#order_customer_address_two').removeClass('readonly');
      $('#order_customer_city').removeClass('readonly');
      $('#order_customer_state').removeClass('readonly');
      $('#order_customer_zip').removeClass('readonly');
      $('#order_customer_address_one').prop('readonly', false);
      $('#order_customer_address_two').prop('readonly', false);
      $('#order_customer_city').prop('readonly', false);
      $('#order_customer_state').prop('readonly', false);
      $('#order_customer_zip').prop('readonly', false);
    }
  });
})();
