import React, { useState } from 'react';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';

// NOTE: We were getting an error to use setAppElement to hide
// the app while the modal is open, for use by screenreaders. So
// it's currently being set to `main`.

Modal.setAppElement('.main');

export default function EmbStitchEstimateModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleOpen() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <>
      <button className="button-naked" type="button" onClick={handleOpen}>
        <i className="fa fa-info-circle notify mls"></i>
        <span className="is-hidden-visually">
          Not sure what your stitch count is?
        </span>
      </button>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Stitch Count Estimates"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
          <h4 className="react-modal-header__title">Stitch Count Estimates</h4>
          <button className="button-naked" type="button" onClick={closeModal}>
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          <p>To get a rough estimate, we recommend the following: </p>
          <div className="stack stack--x-small bg-pale-gray pal">
            <p>
              <strong>Small Design</strong>: 5000 to 7500 Stitches{' '}
            </p>
            <p>
              <strong>Medium Design</strong>: 7500 to 15000 Stitches
            </p>
            <p>
              <strong>Large Design</strong>: 15000 to 25000 Stitches
            </p>
          </div>
          <p className="txt-muted txt-small">
            If you have an extra large or highly detailed design, reach out to
            your BDL for a more precise estimate
          </p>
        </div>
      </Modal>
    </>
  );
}
